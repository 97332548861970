// Auth
export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const TRAVELER_SIGN_UP = 'TRAVELER_SIGN_UP';
export const SSO_LOGIN = 'SSO_LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const FETCH_ALL_ORGANIZATIONS = 'FETCH_ALL_ORGANIZATIONS';
export const CHECK_CURRENT_USER = 'CHECK_CURRENT_USER';
export const CLEAR_EMAIL_ERROR = 'CLEAR_EMAIL_ERROR';
export const AUTHORIZED_FOR = 'AUTHORIZED_FOR';
export const FETCH_USER = 'FETCH_USER';
export const RESET_AUTH_LOADING = 'RESET_AUTH_LOADING';
export const CHECK_USER_FAVORITE_DISCLAIMER = 'CHECK_USER_FAVORITE_DISCLAIMER';

// Client
// This is temporary! This can be changed AFTER Travelers Dashboard endpoints
export const FETCH_TRAVELERS = 'FETCH_TRAVELERS';
export const LOADING_FILTER_DATA = 'LOADING_FILTER_DATA';
export const FILTER_TRAVELERS = 'FILTER_TRAVELERS';
export const FETCH_INITIAL_TRAVELERS = 'FETCH_INITIAL_TRAVELERS';
export const FETCH_CLIENT_EVENTS = 'FETCH_CLIENT_EVENTS';
export const FETCH_CLIENT_FORMS = 'FETCH_CLIENT_FORMS';
export const FETCH_CLIENT_APPLICATIONS = 'FETCH_CLIENT_APPLICATIONS';
export const FETCH_CLIENT_PROGRAMS = 'FETCH_CLIENT_PROGRAMS';
export const FETCH_INCREMENTAL_CLIENT_PROGRAMS = 'FETCH_INCREMENTAL_CLIENT_PROGRAMS';
export const FETCH_CLIENT_AUTHORIZED_PROGRAMS = 'FETCH_CLIENT_AUTHORIZED_PROGRAMS';
export const FETCH_CLIENT_PROGRAM_ORGS = 'FETCH_CLIENT_PROGRAMS_ORGS';
export const FETCH_CLIENT_ADMINS = 'FETCH_CLIENT_ADMINS';
export const CREATE_TRAVELER_BATCH_MESSAGE = 'CREATE_TRAVELER_BATCH_MESSAGE';
export const FETCH_COLLEGE_OR_FACULTY = 'FETCH_COLLEGE_OR_FACULTY';
export const SELECT_TRAVELER = 'SELECT_TRAVELER';
export const UNSELECT_TRAVELER = 'UNSELECT_TRAVELER';
export const SELECT_TRAVELERS = 'SELECT_TRAVELERS';
export const UNSELECT_TRAVELERS = 'UNSELECT_TRAVELERS';
export const APPLY_SEARCH_FILTER = 'APPLY_SEARCH_FILTER';
export const ADD_FILTER = 'ADD_FILTER';
export const CLEAR_FILTER_MESSAGE = 'CLEAR_FILTER_MESSAGE';
export const CLEAR_SEARCH_FILTER_APPLIED = 'CLEAR_SEARCH_FILTER_APPLIED';
export const DIAL_FILTER = 'DIAL_FILTER';
export const DELETE_SEARCH_FILTER = 'DELETE_SEARCH_FILTER';
export const CLEAR_SEARCH_FILTER_CODE = 'CLEAR_SEARCH_FILTER_CODE';
export const ALTERNATE_PROGRAM_COUNT = 'ALTERNATE_PROGRAM_COUNT';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';
export const FETCH_CLIENT_DASHBOARD_DETAILS = 'FETCH_CLIENT_DASHBOARD_DETAILS';
// Traveler Dashboard

// Profile
export const SET_CURRENT_USER_LOADING = 'SET_CURRENT_USER_LOADING';
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA';
export const FETCH_TRAVELER_DETAILS = 'FETCH_TRAVELER_DETAILS';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const FETCH_TRAVELER_PREFERENCES = 'FETCH_TRAVELER_PREFERENCES';
export const UPDATE_TRAVELER_PREFERENCES = 'UPDATE_TRAVELER_PREFERENCES';
export const FETCH_TRAVELER_NOTES = 'FETCH_TRAVELER_NOTES';
export const FETCH_TRAVELER_HEADER = 'FETCH_TRAVELER_HEADER';
export const FETCH_TRAVELER_INSIGHT = 'FETCH_TRAVELER_INSIGHT';
export const FETCH_TRAVELER_MESSAGES = 'FETCH_TRAVELER_MESSAGES';
export const FETCH_TRAVELER_MESSAGES_REQUEST = 'FETCH_TRAVELER_MESSAGES_REQUEST';
export const FETCH_TRAVELER_MESSAGES_FAILURE = 'FETCH_TRAVELER_MESSAGES_FAILURE';
export const CREATE_TRAVELER_MESSAGE = 'CREATE_TRAVELER_MESSAGE';
export const CREATE_TRAVELER_MESSAGE_LOADING = 'CREATE_TRAVELER_MESSAGE_LOADING';
export const CHECK_TRAVELER_MESSAGE = 'CHECK_TRAVELER_MESSAGE';
export const FETCH_ASSIGNED_ADMINS = 'FETCH_ASSIGNED_ADMINS';
export const UPDATE_ASSIGNED_ADMINS = 'UPDATE_ASSIGNED_ADMINS';
export const CREATE_TRAVELER_NOTE = 'CREATE_TRAVELER_NOTE';
export const SELECT_TRAVELER_PROFILE_TAB = 'SELECT_TRAVELER_PROFILE_TAB';
export const SELECT_MESSAGE_TAB = 'SELECT_MESSAGE_TAB';
export const RESET_PROFILE_CODE = 'RESET_PROFILE_CODE';
export const RESET_MESSAGE_CREATE_CODE = 'RESET_MESSAGE_CREATE_CODE';
export const FETCH_NEW_MESSAGES = 'FETCH_NEW_MESSAGES';
export const FETCH_NEW_MESSAGES_TRAVELER = 'FETCH_NEW_MESSAGES_TRAVELER';
export const ADD_NEW_FORM = 'ADD_NEW_FORM';
export const MANUAL_SYNC_TRAVELER = 'MANUAL_SYNC_TRAVELER';
export const CLEAR_MANUAL_SYNC_TRAVELER = 'CLEAR_MANUAL_SYNC_TRAVELER';

export const DELETE_TRAVELER_REQUEST = 'DELETE_TRAVELER_REQUEST';
export const DELETE_TRAVELER_SUCCESS = 'DELETE_TRAVELER_SUCCESS';
export const DELETE_TRAVELER_CLEAR = 'DELETE_TRAVELER_CLEAR';
export const DELETE_TRAVELER_FAILURE = 'DELETE_TRAVELER_FAILURE';

// Traveler
export const FETCH_HEADER = 'FETCH_HEADER';
export const FETCH_TRAVELER_DATA = 'FETCH_TRAVELER_DATA';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_APPLICATIONS = 'FETCH_APPLICATIONS';
export const FETCH_FAVORITES = 'FETCH_FAVORITES';
export const FETCH_FORMS = 'FETCH_FORMS';
export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const FETCH_VISITOR_EVENT = 'FETCH_VISITOR_EVENT';
export const FETCH_VISITOR_EVENT_REQUEST = 'FETCH_VISITOR_EVENT_REQUEST'; 
export const FETCH_VISITOR_EVENT_FAILURE = 'FETCH_VISITOR_EVENT_FAILURE'; 
export const VISITOR_SINGLE_TRAVELER = 'VISITOR_SINGLE_TRAVELER';


// Events
export const FETCH_ADMIN_EVENTS = 'FETCH_ADMIN_EVENTS';
export const FETCH_ADMIN_EVENT_DATA = 'FETCH_ADMIN_EVENT_DATA';
export const FETCH_ADMIN_EVENT_TRAVELERS = 'FETCH_ADMIN_EVENT_TRAVELERS';
export const TOGGLE_SELECTED_ADMIN_EVENT = 'TOGGLE_SELECTED_ADMIN_EVENT';
export const CANCEL_EVENT = 'CANCEL_EVENT';
export const ACTIVATE_EVENT = 'ACTIVATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const DUPLICATE_EVENT = 'DUPLICATE_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const TOGGLE_EVENT_TRAVELER = 'TOGGLE_EVENT_TRAVELER';
export const BULK_INVITE_TRAVELERS = 'BULK_INVITE_TRAVELERS';
export const INVITE_SINGLE_TRAVELER = 'INVITE_SINGLE_TRAVELER';
export const SAME_DAY_REGISTER = 'SAME_DAY_REGISTER';
export const DOWNLOAD_EVENT_TRAVELERS = 'DOWNLOAD_EVENT_TRAVELERS';
export const CLEAR_EVENT_STATUSES_AND_ALERTS = 'CLEAR_EVENT_STATUSES_AND_ALERTS';


// Travel Plans
export const FETCH_TRAVEL_PLANS = 'FETCH_TRAVEL_PLANS';
export const FETCH_ADMIN_TRAVEL_PLAN_DATA = 'FETCH_ADMIN_TRAVEL_PLAN_DATA';
export const FETCH_TRAVEL_PLAN_LOGISTICS = 'FETCH_TRAVEL_PLAN_LOGISTICS';
export const SELECT_TRAVEL_PLAN_TAB = 'SELECT_TRAVEL_PLAN_TAB';
export const ADD_HOUSING_LOGISTIC = 'ADD_HOUSING_LOGISTIC';
export const REMOVE_EVENT_TRAVELER_REQUEST = 'REMOVE_EVENT_TRAVELER_REQUEST';
export const REMOVE_EVENT_TRAVELER_SUCCESS = 'REMOVE_EVENT_TRAVELER_SUCCESS';
export const REMOVE_EVENT_TRAVELER_FAILURE = 'REMOVE_EVENT_TRAVELER_FAILURE';
export const REMOVE_EVENT_TRAVELER_CLEAR = 'REMOVE_EVENT_TRAVELER_CLEAR';
export const TOGGLE_EVENT_TRAVELER_REQUEST = 'TOGGLE_EVENT_TRAVELER_REQUEST';
export const TOGGLE_EVENT_TRAVELER_SUCCESS = 'TOGGLE_EVENT_TRAVELER_SUCCESS';
export const TOGGLE_EVENT_TRAVELER_FAILURE = 'TOGGLE_EVENT_TRAVELER_FAILURE';
export const TOGGLE_EVENT_TRAVELER_CLEAR = 'TOGGLE_EVENT_TRAVELER_CLEAR';

// Intake
export const FETCH_INTAKE = 'FETCH_INTAKE';
export const ADD_INTAKE_PROFILE = 'ADD_INTAKE_PROFILE';
export const ADD_INTAKE_TRAVELER_INFO = 'ADD_INTAKE_TRAVELER_INFO';
export const ADD_INTAKE_COUNTRIES_OF_CITIZENSHIP = 'ADD_INTAKE_COUNTRIES_OF_CITIZENSHIP';
export const ADD_INTAKE_TRAVELER_TYPE = 'ADD_INTAKE_TRAVELER_TYPE';
export const ADD_INTAKE_HOME_INSTITUTION = 'ADD_INTAKE_HOME_INSTITUTION';
export const ADD_INTAKE_ADVISING = 'ADD_INTAKE_ADVISING';
export const ADD_INTAKE_FINANCIAL_INFO = 'ADD_INTAKE_FINANCIAL_INFO';
export const ADD_INTAKE_FINANCIAL_AID = 'ADD_INTAKE_FINANCIAL_AID';
export const ADD_INTAKE_GOAL = 'ADD_INTAKE_GOAL';
export const ADD_INTAKE_TRAVEL_EXPERIENCE = 'ADD_INTAKE_TRAVEL_EXPERIENCE';
export const ADD_INTAKE_PROGRAM_PREFERENCE = 'ADD_INTAKE_PROGRAM_PREFERENCE';
export const ADD_INTAKE_LANGUAGES = 'ADD_INTAKE_LANGUAGES';
export const ADD_INTAKE_PASSPORT = 'ADD_INTAKE_PASSPORT';
export const NEXT_INTAKE_STEP = 'NEXT_INTAKE_STEP';
export const PREVIOUS_INTAKE_STEP = 'PREVIOUS_INTAKE_STEP';
export const CHANGE_INTAKE_STEP = 'CHANGE_INTAKE_STEP';
export const ADD_INTAKE_COMPLETE = 'ADD_INTAKE_COMPLETE';

// Program Match
export const NEXT_PROGRAM_MATCH_STEP = 'NEXT_PROGRAM_MATCH_STEP';
export const PREVIOUS_PROGRAM_MATCH_STEP = 'PREVIOUS_PROGRAM_MATCH_STEP';
export const CHANGE_PROGRAM_MATCH_STEP = 'CHANGE_PROGRAM_MATCH_STEP';
export const FETCH_PROGRAM_PREFERENCES = 'FETCH_PROGRAM_PREFERENCES';
export const ADD_PROGRAM_TERM_VALUES = 'ADD_PROGRAM_TERM_VALUES';
export const REMOVE_PROGRAM_TERM_VALUES = 'REMOVE_PROGRAM_TERM_VALUES';
export const ADD_PROGRAM_MATCH_PROGRAM_TYPE = 'ADD_PROGRAM_MATCH_PROGRAM_TYPE';
export const ADD_PROGRAM_MATCH_PROGRAM_LENGTH = 'ADD_PROGRAM_MATCH_PROGRAM_LENGTH';
export const ADD_PROGRAM_MATCH_PROGRAM_PREFERENCE = 'ADD_PROGRAM_MATCH_PROGRAM_PREFERENCE';
export const ADD_PROGRAM_MATCH_LANGUAGES = 'ADD_PROGRAM_MATCH_LANGUAGES';
export const ADD_PROGRAM_MATCH_PROGRAM_COUNTRY = 'ADD_PROGRAM_MATCH_PROGRAM_COUNTRY';
export const ADD_PROGRAM_MATCH_PROGRAM_HOUSING = 'ADD_PROGRAM_MATCH_PROGRAM_HOUSING';
export const ADD_PROGRAM_MATCH_SUBJECT_AREAS = 'ADD_PROGRAM_MATCH_SUBJECT_AREAS';
export const ADD_PROGRAM_MATCH_PRIORITIES = 'ADD_PROGRAM_MATCH_PRIORITIES';
export const ADD_PROGRAM_MATCH_PROGRAM_TERMS = 'ADD_PROGRAM_MATCH_PROGRAM_TERMS';
export const ADD_PROGRAM_MATCH_COMPLETED = 'ADD_PROGRAM_MATCH_COMPLETED';

// Program Dashboard

export const FETCH_PROGRAMS = 'FETCH_PROGRAMS';
export const FETCH_UNAUTHORIZED_PROGRAMS = 'FETCH_UNAUTHORIZED_PROGRAMS';
export const AUTHORIZE_PROGRAMS = 'AUTHORIZE_PROGRAMS';
export const DEAUTHORIZE_PROGRAMS = 'DEAUTHORIZE_PROGRAMS';
export const FETCH_PROVIDERS = 'FETCH_PROVIDERS';
export const FILTER_PROGRAM_LOCATION = 'FILTER_PROGRAM_LOCATION';
export const FILTER_PROGRAM_CITIES = 'FILTER_PROGRAM_CITIES';
export const FILTER_PROGRAM_LANGUAGES = 'FILTER_PROGRAM_LANGUAGES';
export const FILTER_PROGRAM_SUBJECTS = 'FILTER_PROGRAM_SUBJECTS';
export const FILTER_LANGUAGE_IMMERSION = 'FILTER_LANGUAGE_IMMERSION';
export const FILTER_PROGRAM_LENGTH = 'FILTER_PROGRAM_LENGTH';
export const FILTER_PROGRAM_CONTACTS = 'FILTER_PROGRAM_CONTACTS';
export const FILTER_PROGRAM_PROVIDERS = 'FILTER_PROGRAM_PROVIDERS';
export const FILTER_PROGRAM_SUPPORT_LEVEL = 'FILTER_PROGRAM_SUPPORT_LEVEL';
export const FILTER_PROGRAM_TYPES = 'FILTER_PROGRAM_TYPES';
export const FILTER_HOUSING_TYPES = 'FILTER_HOUSING_TYPES';
export const FILTER_FAVORITES = 'FILTER_FAVORITES';
export const FILTER_PUBLISH_STATUS = 'FILTER_PUBLISH_STATUS';
export const FILTER_AUTHORIZATION_STATUS = 'FILTER_AUTHORIZATION_STATUS';
export const FILTER_ELIGIBILITY = 'FILTER_ELIGIBILITY';
export const FILTER_START_DATE = 'FILTER_START_DATE';
export const FILTER_END_DATE = 'FILTER_END_DATE';
export const FILTER_TERM_NAME = 'FILTER_TERM_NAME';
export const CLEAR_PROGRAM_FILTERS = 'CLEAR_PROGRAM_FILTERS';
export const TOGGLE_FAVORITE = 'TOGGLE_FAVORITE';
export const SEARCH_PROGRAMS = 'SEARCH_PROGRAMS';
export const BATCH_PUBLISH_PROGRAM = 'BATCH_PUBLISH_PROGRAM';
export const BATCH_UNPUBLISH_PROGRAM = 'BATCH_UNPUBLISH_PROGRAM';
export const ADD_PROGRAM_RANGES = 'ADD_PROGRAM_RANGES';
export const ADD_PROGRAM_ADMINS = 'ADD_PROGRAM_ADMINS';
export const BATCH_ADD_TAGS_SUCCESS = 'BATCH_ADD_TAGS_SUCCESS';
export const BATCH_ADD_TAGS_FAILURE = 'BATCH_ADD_TAGS_FAILURE';
export const PROGRAM_DASHBOARD_LOADING = 'PROGRAM_DASHBOARD_LOADING';
export const CLEAR_PROGRAM_DASHBOARD_FLASHES = 'CLEAR_PROGRAM_DASHBOARD_FLASHES';
export const FILTER_SUPPORT_LEVEL_PROGRAMS = 'FILTER_SUPPORT_LEVEL_PROGRAMS';
export const CLEAR_DRAFT_STATUSES = 'CLEAR_DRAFT_STATUSES';
export const CLEAR_SELECTED_PROGRAMS = 'CLEAR_SELECTED_PROGRAMS';
export const PROGRAM_LOADING = 'PROGRAM_LOADING';
export const CLEAR_PUBLISHED_ID = 'CLEAR_PUBLISHED_ID';

// Program Brochure
export const FETCH_PROGRAM = 'FETCH_PROGRAM';
export const GET_PROGRAM = 'GET_PROGRAM';
export const GET_PROGRAM_BROCHURE = 'GET_PROGRAM_BROCHURE';
export const GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS = 'GET_AUTHORIZED_PROGRAM_ORGANIZATION_SETTINGS';
export const TOGGLE_LEARN_MORE_MODAL = 'TOGGLE_LEARN_MORE_MODAL';
export const MARK_PROGRAM_BROCHURE_AMENITIES_PARSED = 'MARK_PROGRAM_BROCHURE_AMENITIES_PARSED';
export const FETCH_PROGRAM_FOR_TRAVELER = 'FETCH_PROGRAM_FOR_TRAVELER';
export const PUBLISH_PROGRAM = 'PUBLISH_PROGRAM';
export const UNPUBLISH_PROGRAM = 'UNPUBLISH_PROGRAM';
export const SELECT_PROGRAM = 'SELECT_PROGRAM';
export const UNSELECT_PROGRAM = 'UNSELECT_PROGRAM';
export const ADD_MORE_PROGRAM_INFO = 'ADD_MORE_PROGRAM_INFO';
export const FETCH_TRAVELER_PERCENT_MATCH_ONE = 'FETCH_TRAVELER_PERCENT_MATCH_ONE';
export const REQUEST_TO_APPLY = 'REQUEST_TO_APPLY';
export const GET_AUTHORIZED_PROGRAM_SETTINGS = 'GET_AUTHORIZED_PROGRAM_SETTINGS';
export const ASK_QUESTION = 'ASK_QUESTION';
export const FETCH_PROGRAM_CONTACTS = 'FETCH_PROGRAM_CONTACTS';
export const TOGGLE_PROGRAM_PUBLISH = 'TOGGLE_PROGRAM_PUBLISH';
export const FETCH_AMMENITIES = 'FETCH_AMMENITIES';
export const FETCH_SUBJECTS = 'FETCH_SUBJECTS';
export const CREATE_PROGRAM_DRAFT = 'CREATE_PROGRAM_DRAFT';
export const PROGRAM_ERRORS = 'PROGRAM_ERRORS';
export const CONTACT_PROGRAM_CONTACT = 'CONTACT_PROGRAM_CONTACT';
export const CLEAR_IS_CONTACTED = 'CLEAR_IS_CONTACTED';
export const CLEAR_QUESTION_CODE = 'CLEAR_QUESTION_CODE';
export const UPDATE_PROGRAM_LOADER = 'UPDATE_PROGRAM_LOADER';
export const SAVE_NEW_DOCUMENT = 'SAVE_NEW_DOCUMENT';
export const DELETE_PROGRAM_ATTACHMENT = 'DELETE_PROGRAM_ATTACHMENT';
export const UPDATE_PROGRAM_ATTACHMENT = 'UPDATE_PROGRAM_ATTACHMENT';
export const SAVE_NEW_ADMIN_DOCUMENT = 'SAVE_NEW_ADMIN_DOCUMENT';
export const DELETE_ADMIN_DOCUMENT = 'DELETE_ADMIN_DOCUMENT';
export const UPDATE_ADMIN_DOCUMENT = 'UPDATE_ADMIN_DOCUMENT';
export const FAVORITE_DISCLAIMER_ACCEPTED = 'FAVORITE_DISCLAIMER_ACCEPTED';

// Admin Application Templates
export const FETCH_ADMIN_APPLICATIONS = 'FETCH_ADMIN_APPLICATIONS';
export const SEARCH_ADMIN_APPLICATIONS = 'SEARCH_ADMIN_APPLICATIONS';
export const INACTIVATE_APPLICATION = 'INACTIVATE_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const PUBLISH_APPLICATION = 'PUBLISH_APPLICATION';
export const DUPLICATE_APPLICATION = 'DUPLICATE_APPLICATION';

// Application update actions
export const UPDATE_STATUS_APPLICATION = 'UPDATE_STATUS_APPLICATION';
export const ERROR_STATUS_APPLICATION = 'ERROR_STATUS_APPLICATION';

// Admin Forms Templates
export const FETCH_ADMIN_FORMS = 'FETCH_ADMIN_FORMS';
export const SEARCH_ADMIN_FORMS = 'SEARCH_ADMIN_FORMS';
export const INACTIVATE_FORM = 'INACTIVATE_FORM';
export const DELETE_FORM = 'DELETE_FORM';
export const PUBLISH_FORM = 'PUBLISH_FORM';
export const DUPLICATE_FORM = 'DUPLICATE_FORM';
export const CLEAR_FORM = 'CLEAR_FORM';

// Reports
export const GET_REPORT = 'GET_REPORT';
export const GET_AVAILABLE_REPORTS = 'GET_AVAILABLE_REPORTS';

/*
====================
ORG SETTINGS
====================
*/
export const ORG_DETAILS = 'ORG_DETAILS';
export const SELECT_ORG_SETTINGS_TAB = 'SELECT_ORG_SETTINGS_TAB';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';
export const FETCH_CLIENT_USERS = 'FETCH_CLIENT_USERS';
export const UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT = 'UPDATE_ORGANIZATION_DATA_SAFECHECK_TEXT';
export const UPDATE_ORGANIZATION_DATA = 'UPDATE_ORGANIZATION_DATA';
export const UPDATE_ORGANIZATION_USERS = 'UPDATE_ORGANIZATION_USERS';
export const DELETE_ORGANIZATION_USER = 'DELETE_ORGANIZATION_USER';
export const FETCH_ORGANIZATION_USERS = 'FETCH_ORGANIZATION_USERS';
export const FETCH_ORGANIZATION_INFO = 'FETCH_ORGANIZATION_INFO';
export const FETCH_ROLE_SETTINGS = 'FETCH_ROLE_SETTINGS';
export const UPDATE_ROLE_SETTINGS = 'UPDATE_ROLE_SETTINGS';
export const UPDATE_CUSTOM_FIELDS_MODAL = 'UPDATE_CUSTOM_FIELDS_MODAL';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const FETCH_CLIENT_USER_HEADER = 'FETCH_CLIENT_USER_HEADER';
export const RESEND_TRAVELER_INVITE = 'RESEND_TRAVELER_INVITE';
export const RESEND_ADMIN_INVITE = 'RESEND_ADMIN_INVITE';
export const CANCEL_ADMIN_INVITE = 'CANCEL_ADMIN_INVITE';
export const CANCEL_TRAVELER_INVITE = 'CANCEL_TRAVELER_INVITE';
export const INVITE_ORGANIZATION_ADMIN = 'INVITE_ORGANIZATION_ADMIN';
export const FETCH_AUTHORIZED_PROGRAM_SETTINGS = 'FETCH_AUTHORIZED_PROGRAM_SETTINGS';
export const UPDATE_AUTHORIZED_PROGRAM_SETTINGS = 'UPDATE_AUTHORIZED_PROGRAM_SETTINGS';
export const ARCHIVE_ADMIN = 'ARCHIVE_ADMIN';
export const ADD_MESSAGE_RECIPIENT = 'ADD_MESSAGE_RECIPIENT';
export const REMOVE_MESSAGE_RECIPIENT = 'REMOVE_MESSAGE_RECIPIENT';
export const UPDATE_MESSAGE_RECIPIENTS = 'UPDATE_MESSAGE_RECIPIENTS';
export const UPDATE_ADMIN_ACCOUNT = 'UPDATE_ADMIN_ACCOUNT';

export const UPDATE_ADMIN_ACCOUNT_REQUEST = 'UPDATE_ADMIN_ACCOUNT_REQUEST';
export const UPDATE_ADMIN_ACCOUNT_SUCCESS = 'UPDATE_ADMIN_ACCOUNT_SUCCESS';
export const UPDATE_ADMIN_ACCOUNT_FAILURE = 'UPDATE_ADMIN_ACCOUNT_FAILURE';
export const UPDATE_ADMIN_ACCOUNT_CLEAR = 'UPDATE_ADMIN_ACCOUNT_CLEAR';

export const RESET_USERS_CODE = 'RESET_USERS_CODE';
export const CLEAR_ORGANIZATION_FLASH_MESSAGE = 'CLEAR_ORGANIZATION_FLASH_MESSAGE';
export const RESET_ARCHIVE_MESSAGE = 'RESET_ARCHIVE_MESSAGE';
export const RESET_ADMIN_INVITE_MESSAGE = 'RESET_ADMIN_INVITE_MESSAGE';
export const RESET_TRAVELER_INVITE_MESSAGE = 'RESET_TRAVELER_INVITE_MESSAGE';
export const FETCH_MAILER_NOTIFICATIONS = 'FETCH_MAILER_NOTIFICATIONS';
export const FETCH_MAILER_NOTIFICATIONS_OPT_OUTS = 'FETCH_MAILER_NOTIFICATIONS_OPT_OUTS';
export const UPDATE_MAILER_OPT_OUT_REQUEST = 'UPDATE_MAILER_OPT_OUT_REQUEST';
export const UPDATE_MAILER_OPT_OUT_SUCCESS = 'UPDATE_MAILER_OPT_OUT_SUCCESS';
export const UPDATE_MAILER_OPT_OUT_CLEAR = 'UPDATE_MAILER_OPT_OUT_CLEAR';
export const UPDATE_MAILER_OPT_OUT_FAILURE = 'UPDATE_MAILER_OPT_OUT_FAILURE';
export const UPDATE_ORGANIZATION_DATA_REQUEST = 'UPDATE_ORGANIZATION_DATA_REQUEST';
export const UPDATE_ORGANIZATION_DATA_SUCCESS = 'UPDATE_ORGANIZATION_DATA_SUCCESS';
export const UPDATE_ORGANIZATION_DATA_CLEAR = 'UPDATE_ORGANIZATION_DATA_CLEAR';
export const UPDATE_ORGANIZATION_DATA_FAILURE = 'UPDATE_ORGANIZATION_DATA_FAILURE';
export const REMOVE_TRANSFER_ADMIN_REQUEST = 'REMOVE_TRANSFER_ADMIN_REQUEST';
export const REMOVE_TRANSFER_ADMIN_CLEAR = 'REMOVE_TRANSFER_ADMIN_CLEAR';
export const REMOVE_TRANSFER_ADMIN_SUCCESS = 'REMOVE_TRANSFER_ADMIN_SUCCESS';
export const REMOVE_TRANSFER_ADMIN_FAILURE = 'REMOVE_TRANSFER_ADMIN_FAILURE';
export const FETCH_CUSTOM_FIELDS_REQUEST = 'FETCH_CUSTOM_FIELDS_REQUEST';
export const FETCH_CUSTOM_FIELDS_CLEAR = 'FETCH_CUSTOM_FIELDS_CLEAR';
export const FETCH_CUSTOM_FIELDS_SUCCESS = 'FETCH_CUSTOM_FIELDS_SUCCESS';
export const FETCH_CUSTOM_FIELDS_FAILURE = 'FETCH_CUSTOM_FIELDS_FAILURE';
export const UPDATE_CUSTOM_FIELDS_REQUEST = 'UPDATE_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CUSTOM_FIELDS_CLEAR = 'UPDATE_CUSTOM_FIELDS_CLEAR';
export const UPDATE_CUSTOM_FIELDS_SUCCESS = 'UPDATE_CUSTOM_FIELDS_SUCCESS';
export const UPDATE_CUSTOM_FIELDS_FAILURE = 'UPDATE_CUSTOM_FIELDS_FAILURE';
export const ADD_CUSTOM_FIELDS_CLEAR = 'ADD_CUSTOM_FIELDS_CLEAR,';
export const ADD_CUSTOM_FIELDS_REQUEST = 'ADD_CUSTOM_FIELDS_REQUEST,';
export const ADD_CUSTOM_FIELDS_SUCCESS = 'ADD_CUSTOM_FIELDS_SUCCESS,';
export const ADD_CUSTOM_FIELDS_FAILURE = 'ADD_CUSTOM_FIELDS_FAILURE,';
/*
====================
TRAVELER SETTINGS
====================
*/
export const FETCH_TRAVELER_SETTINGS_HEADER = 'FETCH_TRAVELER_SETTINGS_HEADER';
export const SELECT_TRAVELER_SETTINGS = 'SELECT_TRAVELER_SETTINGS';
export const SELECT_TRAVELER_SETTINGS_TAB = 'SELECT_TRAVELER_SETTINGS_TAB';
export const UPDATE_TRAVELER_ACCOUNT = 'UPDATE_TRAVELER_ACCOUNT';
/*
====================
TAGS
====================
*/
export const CREATE_TAG = 'CREATE_TAG';
export const GET_TAGS = 'GET_TAGS';
export const TAGS_LOADING = 'TAGS_LOADING';
export const ARCHIVE_TAG = 'ARCHIVE_TAG';
export const GET_ARCHIVED_TAGS = 'GET_ARCHIVED_TAGS';
export const RESTORE_TAG = 'RESTORE_TAG';
export const CLEAR_TAG_FLASH = 'CLEAR_TAG_FLASH';

/*
=============================
PROGRAM ALTERNATE SETTINGS
=============================
*/

export const SAVE_ALTERNATE_SETTINGS = 'SAVE_ALTERNATE_SETTINGS';
export const ALTERNATE_LOADING = 'ALTERNATE_LOADING';
export const CLEAR_ALTERNATE_FLASH = 'CLEAR_ALTERNATE_FLASH';
/*
=============================
PROGRAM SETTINGS
=============================
*/
export const FETCH_PROGRAM_HASH = 'FETCH_PROGRAM_HASH';
export const SET_PROGRAM_SETTINGS_ERROR = 'SET_PROGRAM_SETTINGS_ERROR';
export const SET_EXTERNAL_URL = 'SET_EXTERNAL_URL';
export const REPLACE_UPDATED_PROGRAM_OBJECT = 'REPLACE_UPDATED_PROGRAM_OBJECT';
export const ADD_NEW_TERM = 'ADD_NEW_TERM';
export const SETTING_ERRORS = 'SETTING_ERRORS';
export const CLEAR_PROGRAM_SETTINGS_ERRORS = 'CLEAR_PROGRAM_SETTINGS_ERRORS';
export const SHOW_PROGRAM_SETTINGS_LOADER = 'SHOW_PROGRAM_SETTINGS_LOADER';
export const TOGGLE_STATUS = 'TOGGLE_STATUS';
export const GET_AVAILABLE_TAGS = 'GET_AVAILABLE_TAGS';
export const CLEAR_AVAILABLE_TAGS = 'CLEAR_AVAILABLE_TAGS';
export const AVAILABLE_TAGS_LOADING = 'AVAILABLE_TAGS_LOADING';
export const GET_PAGINATED_TAGS = 'GET_PAGINATED_TAGS';
export const HANDLE_CLEAR_STATE = 'HANDLE_CLEAR_STATE';
export const TOGGLE_UPDATE_STATES = 'TOGGLE_UPDATE_STATES';
export const SEARCH_TAGS = 'SEARCH_TAGS';
export const SEARCH_FORMS = 'SEARCH_FORMS';
export const CANCEL_PROGRAM_TERM = 'CANCEL_PROGRAM_TERM';
export const UNCANCEL_PROGRAM_TERM = 'UNCANCEL_PROGRAM_TERM';
export const EDIT_TAG_REQUEST = 'EDIT_TAG_REQUEST';
export const EDIT_TAG_SUCCESS = 'EDIT_TAG_SUCCESS';
export const EDIT_TAG_FAILURE = 'EDIT_TAG_FAILURE';
export const TAG_CLEAR = 'TAG_CLEAR';
export const CREATE_TAG_REQUEST = 'CREATE_TAG_REQUEST';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';
export const CREATE_TAG_FAILURE = 'CREATE_TAG_FAILURE';

/*
===========================
Form Submission actions
==========================
*/
export const SAVE_APPLICATION_DATA = 'SAVE_APPLICATION_DATA';
export const PUT_APPLICATION_DATA_TO_SERVER = 'PUT_APPLICATION_DATA_TO_SERVER';
export const SEND_RECOMMENDATION_REQUEST = 'SEND_RECOMMENDATION_REQUEST';
export const SEND_RECOMMENDATION_SUCCESS = 'SEND_RECOMMENDATION_SUCCESS';
export const SEND_RECOMMENDATION_FAILURE = 'SEND_RECOMMENDATION_FAILURE';
export const RESEND_RECOMMENDATION_REQUEST = 'RESEND_RECOMMENDATION_REQUEST';
export const RESEND_RECOMMENDATION_SUCCESS = 'RESEND_RECOMMENDATION_SUCCESS';
export const RESEND_RECOMMENDATION_FAILURE = 'RESEND_RECOMMENDATION_FAILURE';
export const CANCEL_RECOMMENDATION_REQUEST = 'CANCEL_RECOMMENDATION_REQUEST';
export const CANCEL_RECOMMENDATION_SUCCESS = 'CANCEL_RECOMMENDATION_SUCCESS';
export const CANCEL_RECOMMENDATION_FAILURE = 'CANCEL_RECOMMENDATION_FAILURE';
// Invitations Modal
export const BATCH_INVITE_TRAVELERS_DASHBOARD = 'BATCH_INVITE_TRAVELERS_DASHBOARD';
export const INVITE_SINGLE_TRAVELER_DASHBOARD = 'INVITE_SINGLE_TRAVELER_DASHBOARD';
export const CLEAR_BATCH_INVITATIONS_MESSAGE = 'CLEAR_BATCH_INVITATIONS_MESSAGE';
export const CLEAR_INVITATIONS_MESSAGE = 'CLEAR_INVITATIONS_MESSAGE';

/*
===========================
Traveler Application actions
==========================
*/

export const FETCH_TRAVELER_APPLICATION = 'FETCH_TRAVELER_APPLICATION';
export const CLIENT_FETCH_TRAVELER_APPLICATION = 'CLIENT_FETCH_TRAVELER_APPLICATION';
export const CLIENT_UPDATE_TRAVELER_APPLICATION = 'CLIENT_UPDATE_TRAVELER_APPLICATION';
export const SEND_RECOMMENDATION = 'SEND_RECOMMENDATION';
export const FETCH_RECOMMENDATION = 'FETCH_RECOMMENDATION';
export const SUBMIT_RECOMMENDATION = 'SUBMIT_RECOMMENDATION';
export const CANCEL_RECOMMENDATION = 'CANCEL_RECOMMENDATION';
export const RESEND_RECOMMENDATION = 'RESEND_RECOMMENDATION';
export const ACCESS_DENIED = 'ACCESS_DENIED';

/*
==========================
Traveler Form actions
==========================
*/
export const FETCH_TRAVELER_FORM = 'FETCH_TRAVELER_FORM';
export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const ADMIN_UPDATE_PAYMENT_STATUS = 'ADMIN_UPDATE_PAYMENT_STATUS';
export const UPDATE_APPLICATION_PAYMENT_STATUS = 'UPDATE_APPLICATION_PAYMENT_STATUS';
export const ADMIN_UPDATE_APPLICATION_PAYMENT_STATUS = 'ADMIN_UPDATE_APPLICATION_PAYMENT_STATUS';
export const CLIENT_FETCH_TRAVELER_FORM = 'CLIENT_FETCH_TRAVELER_FORM';
export const CLIENT_UPDATE_TRAVELER_FORM = 'CLIENT_UPDATE_TRAVELER_FORM';
export const UPDATE_STATUS_FORM = 'UPDATE_STATUS_FORM';
export const ERROR_STATUS_FORM = 'ERROR_STATUS_FORM';
export const GET_TRAVELER_APPLICATION = 'GET_TRAVELER_APPLICATION';
export const APPLICATION_LOADING = 'APPLICATION_LOADING';
export const APPLICATION_LOADED = 'APPLICATION_LOADED';
export const SET_TRANSFER_APPLICATION_ERROR = 'SET_TRANSFER_APPLICATION_ERROR';
export const SET_TRANSFER_APPLICATION_SUCCESS = 'SET_TRANSFER_APPLICATION_SUCCESS';
export const SET_ALTERNATE_PROGRAM_SUCCESS = 'SET_ALTERNATE_PROGRAM_SUCCESS';
export const GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE = 'GET_PROGRAM_RANGES_WITH_SAME_TEMPLATE';

/*
===========================
Admin Application Dashboard
==========================
*/
export const SUBMITTED_APPLICATIONS = 'SUBMITTED_APPLICATIONS';
export const SELECTED_APPLICATION = 'SELECTED_APPLICATION';
export const UPDATED_STATUS_RESPONSE = 'UPDATED_STATUS_RESPONSE';
export const CLEAR_FLASH = 'CLEAR_FLASH';
export const FETCH_APPLICATION_DASHBOARD_FILTERS = 'FETCH_APPLICATION_DASHBOARD_FILTERS';
export const DATA_LOADING = 'DATA_LOADING';
export const DATA_LOADED = 'DATA_LOADED';
export const RESET_FILTER = 'RESET_FILTER';
export const QUESTION_TYPES = 'QUESTION_TYPES';
export const LOAD_MORE_SUBMITTED_APPLICATIONS = 'LOAD_MORE_SUBMITTED_APPLICATIONS';
export const CLEAR_PAGE = 'CLEAR_PAGE';
export const LOAD_FILTER_DATA = 'LOAD_FILTER_DATA';

/*
===========================
Template Actions
==========================
*/
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const GET_TEMPLATE = 'GET_TEMPLATE';
export const TEMPLATE_UPDATE = 'TEMPLATE_UPDATE';
export const UPDATE_TEMPLATE_WITH_OUT_SYNC = 'UPDATE_TEMPLATE_WITH_OUT_SYNC';
export const GET_TEMPLATES = 'GET_TEMPLATES';

export const UPDATE_TEMPLATE_STATE_ONLY = 'UPDATE_TEMPLATE_STATE_ONLY';
/*
====================
Announcement Actions
====================
*/
export const FETCH_ANNOUNCEMENTS = 'FETCH_ANNOUNCEMENTS';
export const REMOVE_ANNOUNCEMENT = 'REMOVE_ANNOUNCEMENT';

/*
====================
2020 Travel Plans
====================
*/
export const FETCH_PROGRAM_LINK = 'FETCH_PROGRAM_LINK';

export const SELECTED_PLAN = 'SELECTED_PLAN';
export const CLEAR_SELECTED_PLAN = 'CLEAR_SELECTED_PLAN';
export const ALL_PLANS = 'ALL_PLANS';
export const ADD_MODIFY_PLAN = 'ADD_MODIFY_PLAN';
export const CLEAR_ADD_MODIFY_PLAN = 'CLEAR_ADD_MODIFY_PLAN';
export const ADD_MODIFY_ITINERARY_ITEM = 'ADD_MODIFY_ITINERARY_ITEM';
export const CLEAR_ADD_MODIFY_ITINERARY_ITEM = 'CLEAR_ADD_MODIFY_ITINERARY_ITEM';
export const DELETE_ITINERARY_ITEM = 'DELETE_ITINERARY_ITEM';
export const CLEAR_DELETE_ITINERARY_ITEM = 'CLEAR_DELETE_ITINERARY_ITEM';
export const TRAVELER_LINK_TO_PLAN = 'TRAVELER_LINK_TO_PLAN';
export const TRAVELER_UNLINK_FROM_PLAN = 'TRAVELER_UNLINK_FROM_PLAN';
export const CLEAR_TRAVELER_LINK_TO_PLAN = 'CLEAR_TRAVELER_LINK_TO_PLAN';
export const CLEAR_TRAVELER_UNLINK_FROM_PLAN = 'CLEAR_TRAVELER_UNLINK_FROM_PLAN';
export const ADD_TRAVELERS_REQUEST = 'ADD_TRAVELERS_REQUEST';
export const ADD_TRAVELERS_SUCCESS = 'ADD_TRAVELERS_SUCCESS';
export const ADD_TRAVELERS_FAILURE = 'ADD_TRAVELERS_FAILURE';
export const ADD_TRAVELERS_CLEAR = 'ADD_TRAVELERS_CLEAR';

/*
====================
2020 PLANS
====================
*/
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';
export const ADDPATCH_PLAN_REQUEST = 'ADDPATCH_PLAN_REQUEST';
export const ADDPATCH_PLAN_SUCCESS = 'ADDPATCH_PLAN_SUCCESS';
export const ADDPATCH_PLAN_FAILURE = 'ADDPATCH_PLAN_FAILURE';
export const ADDPATCH_PLAN_CLEAR = 'ADDPATCH_PLAN_CLEAR';
export const ADDPATCH_DETAIL_REQUEST = 'ADDPATCH_DETAIL_REQUEST';
export const ADDPATCH_DETAIL_SUCCESS = 'ADDPATCH_DETAIL_SUCCESS';
export const ADDPATCH_DETAIL_FAILURE = 'ADDPATCH_DETAIL_FAILURE';
export const ADDPATCHUSER_PLAN_REQUEST = 'ADDPATCHUSER_PLAN_REQUEST';
export const ADDPATCHUSER_PLAN_SUCCESS = 'ADDPATCHUSER_PLAN_SUCCESS';
export const ADDPATCHUSER_PLAN_FAILURE = 'ADDPATCHUSER_PLAN_FAILURE';
export const ADDPATCHUSER_PLAN_CLEAR = 'ADDPATCHUSER_PLAN_CLEAR';
export const GET_ALL_TRAVELERS = 'GET_ALL_TRAVELERS';

export const ADD_DETAIL_TO_TRAVELER_REQUEST = 'ADD_DETAIL_TO_TRAVELER_REQUEST';
export const ADD_DETAIL_TO_TRAVELER_SUCCESS = 'ADD_DETAIL_TO_TRAVELER_SUCCESS';
export const ADD_DETAIL_TO_TRAVELER_FAILURE = 'ADD_DETAIL_TO_TRAVELER_FAILURE';
export const ADD_DETAIL_TO_TRAVELER_CLEAR = 'ADD_DETAIL_TO_TRAVELER_CLEAR';

export const SEND_EMAIL_TO_TRAVELERS = 'SEND_EMAIL_TO_TRAVELERS';
export const SEND_EMAIL_TO_TRAVELERS_FAILURE = 'SEND_EMAIL_TO_TRAVELERS_FAILURE';
export const SEND_EMAIL_TO_TRAVELERS_CLEAR = 'SEND_EMAIL_TO_TRAVELERS_CLEAR';
export const SEND_EMAIL_TO_TRAVELERS_SUCCESS = 'SEND_EMAIL_TO_TRAVELERS_SUCCESS';

export const PATCH_DETAIL_FROM_TRAVELER_REQUEST = 'PATCH_DETAIL_FROM_TRAVELER_REQUEST';
export const PATCH_DETAIL_FROM_TRAVELER_SUCCESS = 'PATCH_DETAIL_FROM_TRAVELER_SUCCESS';
export const PATCH_DETAIL_FROM_TRAVELER_FAILURE = 'PATCH_DETAIL_FROM_TRAVELER_FAILURE';
export const PATCH_DETAIL_FROM_TRAVELER_CLEAR = 'PATCH_DETAIL_FROM_TRAVELER_CLEAR';

export const PATCH_PLAN_USER_REQUEST = 'PATCH_PLAN_USER_REQUEST';
export const PATCH_PLAN_USER_SUCCESS = 'PATCH_PLAN_USER_SUCCESS';
export const PATCH_PLAN_USER_FAILURE = 'PATCH_PLAN_USER_FAILURE';
export const PATCH_PLAN_USER_CLEAR = 'PATCH_PLAN_USER_CLEAR';

export const SEND_SAFECHECK_MESSAGE_REQUEST = 'SEND_SAFECHECK_MESSAGE_REQUEST';
export const SEND_SAFECHECK_MESSAGE_SUCCESS = 'SEND_SAFECHECK_MESSAGE_SUCCESS';
export const SEND_SAFECHECK_FAILURE = 'SEND_SAFECHECK_FAILURE';
export const SEND_SAFECHECK_CLEAR = 'SEND_SAFECHECK_CLEAR';

export const SEND_SAFECHECK_ENROLLMENT_REQUEST = 'SEND_SAFECHECK_ENROLLMENT_REQUEST';
export const SEND_SAFECHECK_ENROLLMENT_SUCCESS = 'SEND_SAFECHECK_ENROLLMENT_SUCCESS';
export const SEND_SAFECHECK_ENROLLMENT_FAILURE = 'SEND_SAFECHECK_ENROLLMENT_FAILURE';
export const SEND_SAFECHECK_ENROLLMENT_CLEAR = 'SEND_SAFECHECK_ENROLLMENT_CLEAR';

export const FETCH_SAFECHECK_RECEIPT_REQUEST = 'FETCH_SAFECHECK_RECEIPT_REQUEST';
export const FETCH_SAFECHECK_RECEIPT_SUCCESS = 'FETCH_SAFECHECK_RECEIPT_SUCCESS';
export const FETCH_SAFECHECK_RECEIPT_FAILURE = 'FETCH_SAFECHECK_RECEIPT_FAILURE';
export const FETCH_SAFECHECK_RECEIPT_CLEAR = 'FETCH_SAFECHECK_RECEIPT_CLEAR';

export const SUBMIT_SAFECHECK_RECEIPT_REQUEST = 'SUBMIT_SAFECHECK_RECEIPT_REQUEST';
export const SUBMIT_SAFECHECK_RECEIPT_SUCCESS = 'SUBMIT_SAFECHECK_RECEIPT_SUCCESS';
export const SUBMIT_SAFECHECK_RECEIPT_FAILURE = 'SUBMIT_SAFECHECK_RECEIPT_FAILURE';
export const SUBMIT_SAFECHECK_RECEIPT_CLEAR = 'SUBMIT_SAFECHECK_RECEIPT_CLEAR';

export const FETCH_SAFECHECK_MESSAGES_REQUEST = 'FETCH_SAFECHECK_MESSAGES_REQUEST';
export const FETCH_SAFECHECK_MESSAGES_SUCCESS = 'FETCH_SAFECHECK_MESSAGES_SUCCESS';
export const FETCH_SAFECHECK_MESSAGES_FAILURE = 'FETCH_SAFECHECK_MESSAGES_FAILURE';
export const FETCH_SAFECHECK_MESSAGES_CLEAR = 'FETCH_SAFECHECK_MESSAGES_CLEAR';

export const FETCH_SAFECHECK_MESSAGE_REQUEST = 'FETCH_SAFECHECK_MESSAGE_REQUEST';
export const FETCH_SAFECHECK_MESSAGE_SUCCESS = 'FETCH_SAFECHECK_MESSAGE_SUCCESS';
export const FETCH_SAFECHECK_MESSAGE_FAILURE = 'FETCH_SAFECHECK_MESSAGE_FAILURE';
export const FETCH_SAFECHECK_MESSAGE_CLEAR = 'FETCH_SAFECHECK_MESSAGE_CLEAR';

export const RESEND_SAFECHECK_MESSAGE_REQUEST = 'RESEND_SAFECHECK_MESSAGE_REQUEST';
export const RESEND_SAFECHECK_MESSAGE_SUCCESS = 'RESEND_SAFECHECK_MESSAGE_SUCCESS';
export const RESEND_SAFECHECK_MESSAGE_FAILURE = 'RESEND_SAFECHECK_MESSAGE_FAILURE';
export const RESEND_SAFECHECK_MESSAGE_CLEAR = 'RESEND_SAFECHECK_MESSAGE_CLEAR';
export const VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER';
export const SET_VALIDATE_PHONE_NUMBER_LOADING = 'SET_VALIDATE_PHONE_NUMBER_LOADING';
export const UNENROLL_SAFECHECK_REQUEST = 'UNENROLL_SAFECHECK_REQUEST';
export const UNENROLL_SAFECHECK_SUCCESS = 'UNENROLL_SAFECHECK_SUCCESS';
export const UNENROLL_SAFECHECK_FAILURE = 'UNENROLL_SAFECHECK_FAILURE';

export const BATCH_UPDATE_PLAN_REGISTRATION_STATUS = 'BATCH_UPDATE_PLAN_REGISTRATION_STATUS';
export const BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE = 'BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE';
export const BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR = 'BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR';
export const BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS = 'BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS';

export const BATCH_UPDATE_PLAN_STATUS = 'BATCH_UPDATE_PLAN_STATUS';
export const BATCH_UPDATE_PLAN_STATUS_FAILURE = 'BATCH_UPDATE_PLAN_STATUS_FAILURE';
export const BATCH_UPDATE_PLAN_STATUS_CLEAR = 'BATCH_UPDATE_PLAN_STATUS_CLEAR';
export const BATCH_UPDATE_PLAN_STATUS_SUCCESS = 'BATCH_UPDATE_PLAN_STATUS_SUCCESS';

export const FETCH_TRANSPORTATION_DETAILS = 'FETCH_TRANSPORTATION_DETAILS';
export const FETCH_HOUSING_DETAILS = 'FETCH_HOUSING_DETAILS';
export const FETCH_ACTIVITY_DETAILS = 'FETCH_ACTIVITY_DETAILS';

/*
=======================
General Lookup Actions
=======================
*/
export const FETCH_CONTINENTS = 'FETCH_CONTINENTS';
export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_ACTIVITY_TYPES = 'FETCH_ACTIVITY_TYPES';
export const FETCH_HOUSING_TYPES = 'FETCH_HOUSING_TYPES';
export const FETCH_TRANSPORTATION_TYPES = 'FETCH_TRANSPORTATION_TYPES';
export const CLEAR_DETAIL_TYPES = 'CLEAR_DETAIL_TYPES';
export const FETCH_TRIP_TYPES = 'FETCH_TRIP_TYPES';
export const FETCH_PLAN_TYPES = 'FETCH_PLAN_TYPES';
export const FETCH_SAFECHECK_MESSAGE_TYPES = 'FETCH_SAFECHECK_MESSAGE_TYPES';
export const FETCH_PLAN_STATUS_TYPES = 'FETCH_PLAN_STATUS_TYPES';
export const FETCH_REGISTRATION_STATUS_TYPES = 'FETCH_REGISTRATION_STATUS_TYPES';
export const FETCH_SUBJECT_AREAS = 'FETCH_SUBJECT_AREAS';
export const FETCH_PROGRAM_TYPES = 'FETCH_PROGRAM_TYPES';
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';
export const FETCH_PROGRAM_HOUSING_TYPES = 'FETCH_PROGRAM_HOUSING_TYPES';
export const FETCH_PROGRAM_CLIENT_ADMINS = 'FETCH_PROGRAM_CLIENT_ADMINS';
export const FETCH_PROVIDER_CLIENT_ACCOUNTS = 'FETCH_PROVIDER_CLIENT_ACCOUNTS';
export const FETCH_RESIDENCY_STATUSES = 'FETCH_RESIDENCY_STATUSES';
export const FETCH_PASSPORT_STATUSES = 'FETCH_PASSPORT_STATUSES';
export const FETCH_TRAVELER_TYPES = 'FETCH_TRAVELER_TYPES';
export const FETCH_YEAR_SCHOOL = 'FETCH_YEAR_SCHOOL';
export const FETCH_RACE_OPTIONS = 'FETCH_RACE_OPTIONS';
export const FETCH_APPLICATION_STATUS_OPTIONS = 'FETCH_APPLICATION_STATUS_OPTIONS';
export const FETCH_TRACKING_STEP_OPTIONS = 'FETCH_TRACKING_STEP_OPTIONS';
export const FETCH_PROGRAM_PAYMENT_TYPES = 'FETCH_PROGRAM_PAYMENT_TYPES';
export const FETCH_TRAVELER_BARRIERS_TYPES = 'FETCH_TRAVELER_BARRIERS_TYPES';
export const FETCH_TRAVELER_CERTAINTY_TYPES = 'FETCH_TRAVELER_CERTAINTY_TYPES';
export const FETCH_TRAVELER_GOALS_TYPES = 'FETCH_TRAVELER_GOALS_TYPES';
export const FETCH_GENDER_IDENTITIES = 'FETCH_GENDER_IDENTITIES';
export const FETCH_TEMPLATES = 'FETCH_TEMPLATES';
export const FETCH_SUBMISSION_CONTENTS = 'FETCH_SUBMISSION_CONTENTS';

/*
=======================
General SMS Actions
=======================
*/
export const SEND_SMS_BY_TRIP_USER_ID = 'SEND_SMS_BY_TRIP_USER_ID';

/*
=======================
Reports
=======================
*/
export const FETCH_TRAVELERS_REPORT_REQUEST = 'FETCH_TRAVELERS_REPORT_REQUEST';
export const FETCH_TRAVELERS_REPORT_SUCCESS = 'FETCH_TRAVELERS_REPORT_SUCCESS';
export const FETCH_TRAVELERS_REPORT_FAILURE = 'FETCH_TRAVELERS_REPORT_FAILURE';
export const FETCH_TRAVELERS_REPORT_CLEAR = 'FETCH_TRAVELERS_REPORT_CLEAR';

export const FETCH_TRAVELERS_ACTIVITY_REQUEST = 'FETCH_TRAVELERS_ACTIVITY_REQUEST';
export const FETCH_TRAVELERS_ACTIVITY_SUCCESS = 'FETCH_TRAVELERS_ACTIVITY_SUCCESS';
export const FETCH_TRAVELERS_ACTIVITY_FAILURE = 'FETCH_TRAVELERS_ACTIVITY_FAILURE';
export const FETCH_TRAVELERS_ACTIVITY_CLEAR = 'FETCH_TRAVELERS_ACTIVITY_CLEAR';

export const FETCH_PROGRAMS_REPORT_REQUEST = 'FETCH_PROGRAMS_REPORT_REQUEST';
export const FETCH_PROGRAMS_REPORT_SUCCESS = 'FETCH_PROGRAMS_REPORT_SUCCESS';
export const FETCH_PROGRAMS_REPORT_FAILURE = 'FETCH_PROGRAMS_REPORT_FAILURE';
export const FETCH_PROGRAMS_REPORT_CLEAR = 'FETCH_PROGRAMS_REPORT_CLEAR';

export const FETCH_APPLICATIONS_REPORT_REQUEST = 'FETCH_APPLICATIONS_REPORT_REQUEST';
export const FETCH_APPLICATIONS_REPORT_SUCCESS = 'FETCH_APPLICATIONS_REPORT_SUCCESS';
export const FETCH_APPLICATIONS_REPORT_FAILURE = 'FETCH_APPLICATIONS_REPORT_FAILURE';
export const FETCH_APPLICATIONS_REPORT_CLEAR = 'FETCH_APPLICATIONS_REPORT_CLEAR';

export const FETCH_FORMS_REPORT_REQUEST = 'FETCH_FORMS_REPORT_REQUEST';
export const FETCH_FORMS_REPORT_SUCCESS = 'FETCH_FORMS_REPORT_SUCCESS';
export const FETCH_FORMS_REPORT_FAILURE = 'FETCH_FORMS_REPORT_FAILURE';
export const FETCH_FORMS_REPORT_CLEAR = 'FETCH_FORMS_REPORT_CLEAR';

export const FETCH_REPORT_FILTERS_REQUEST = 'FETCH_REPORT_FILTERS_REQUEST';
export const FETCH_REPORT_FILTERS_SUCCESS = 'FETCH_REPORT_FILTERS_SUCCESS';
export const FETCH_REPORT_FILTERS_FAILURE = 'FETCH_REPORT_FILTERS_FAILURE';
export const FETCH_REPORT_FILTERS_CLEAR = 'FETCH_REPORT_FILTERS_CLEAR';

export const CREATE_REPORT_FILTERS_REQUEST = 'CREATE_REPORT_FILTERS_REQUEST';
export const CREATE_REPORT_FILTERS_SUCCESS = 'CREATE_REPORT_FILTERS_SUCCESS';
export const CREATE_REPORT_FILTERS_FAILURE = 'CREATE_REPORT_FILTERS_FAILURE';
export const CREATE_REPORT_FILTERS_CLEAR = 'CREATE_REPORT_FILTERS_CLEAR';

export const DELETE_REPORT_FILTERS_REQUEST = 'DELETE_REPORT_FILTERS_REQUEST';
export const DELETE_REPORT_FILTERS_SUCCESS = 'DELETE_REPORT_FILTERS_SUCCESS';
export const DELETE_REPORT_FILTERS_FAILURE = 'DELETE_REPORT_FILTERS_FAILURE';
export const DELETE_REPORT_FILTERS_CLEAR = 'DELETE_REPORT_FILTERS_CLEAR';

export const UPDATE_REPORT_FILTERS_REQUEST = 'UPDATE_REPORT_FILTERS_REQUEST';
export const UPDATE_REPORT_FILTERS_SUCCESS = 'UPDATE_REPORT_FILTERS_SUCCESS';
export const UPDATE_REPORT_FILTERS_FAILURE = 'UPDATE_REPORT_FILTERS_FAILURE';
export const UPDATE_REPORT_FILTERS_CLEAR = 'UPDATE_REPORT_FILTERS_CLEAR';

export const FETCH_EVENTS_REPORT_REQUEST = 'FETCH_EVENTS_REPORT_REQUEST';
export const FETCH_EVENTS_REPORT_SUCCESS = 'FETCH_EVENTS_REPORT_SUCCESS';
export const FETCH_EVENTS_REPORT_FAILURE = 'FETCH_EVENTS_REPORT_FAILURE';
export const FETCH_EVENTS_REPORT_CLEAR = 'FETCH_EVENTS_REPORT_CLEAR';

export const FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST = 'FETCH_EVENT_PARTICIPANTS_REPORT_REQUEST';
export const FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS = 'FETCH_EVENT_PARTICIPANTS_REPORT_SUCCESS';
export const FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE = 'FETCH_EVENT_PARTICIPANTS_REPORT_FAILURE';
export const FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR = 'FETCH_EVENT_PARTICIPANTS_REPORT_CLEAR';

export const UNASSIGN_SUITCASES_REQUEST = 'UNASSIGN_SUITCASES_REQUEST';
export const UNASSIGN_SUITCASES_SUCCESS = 'UNASSIGN_SUITCASES_SUCCESS';
export const UNASSIGN_SUITCASES_CLEAR = 'UNASSIGN_SUITCASES_CLEAR';
export const UNASSIGN_SUITCASES_FAILURE = 'UNASSIGN_SUITCASES_FAILURE';

/*
=======================
PROGRAMS - 2021
=======================
*/

export const FETCH_INTERNAL_PROGRAMS_REQUEST = 'FETCH_INTERNAL_PROGRAMS_REQUEST';
export const FETCH_INTERNAL_PROGRAMS_SUCCESS = 'FETCH_INTERNAL_PROGRAMS_SUCCESS';
export const FETCH_INTERNAL_PROGRAMS_FAILURE = 'FETCH_INTERNAL_PROGRAMS_FAILURE';
export const FETCH_INTERNAL_PROGRAMS_CLEAR = 'FETCH_INTERNAL_PROGRAMS_CLEAR';

export const FETCH_EXTERNAL_PROGRAMS_REQUEST = 'FETCH_EXTERNAL_PROGRAMS_REQUEST';
export const FETCH_EXTERNAL_PROGRAMS_SUCCESS = 'FETCH_EXTERNAL_PROGRAMS_SUCCESS';
export const FETCH_EXTERNAL_PROGRAMS_FAILURE = 'FETCH_EXTERNAL_PROGRAMS_FAILURE';
export const FETCH_EXTERNAL_PROGRAMS_CLEAR = 'FETCH_EXTERNAL_PROGRAMS_CLEAR';

export const FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST = 'FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_REQUEST';
export const FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS = 'FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_SUCCESS';
export const FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE = 'FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_FAILURE';
export const FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR = 'FETCH_UNAUTHORIZED_EXTERNAL_PROGRAMS_CLEAR';

export const FETCH_APPLICATION_DATA_REQUEST = 'FETCH_APPLICATION_DATA_REQUEST';
export const FETCH_APPLICATION_DATA_SUCCESS = 'FETCH_APPLICATION_DATA_SUCCESS';
export const FETCH_APPLICATION_DATA_FAILURE = 'FETCH_APPLICATION_DATA_FAILURE';
export const FETCH_APPLICATION_DATA_CLEAR = 'FETCH_APPLICATION_DATA_CLEAR';

export const FETCH_APPLICATION_TEMPLATE_DATA_REQUEST = 'FETCH_APPLICATION_TEMPLATE_DATA_REQUEST';
export const FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS = 'FETCH_APPLICATION_TEMPLATE_DATA_SUCCESS';
export const FETCH_APPLICATION_TEMPLATE_DATA_FAILURE = 'FETCH_APPLICATION_TEMPLATE_DATA_FAILURE';
export const FETCH_APPLICATION_TEMPLATE_DATA_CLEAR = 'FETCH_APPLICATION_TEMPLATE_DATA_CLEAR';

export const PUBLISH_INTERNAL_PROGRAM = 'PUBLISH_INTERNAL_PROGRAM';
export const PUBLISH_INTERNAL_PROGRAM_SUCCESS = 'PUBLISH_INTERNAL_PROGRAM_SUCCESS';
export const PUBLISH_INTERNAL_PROGRAM_FAILURE = 'PUBLISH_INTERNAL_PROGRAM_FAILURE';
export const PUBLISH_INTERNAL_PROGRAM_CLEAR = 'PUBLISH_INTERNAL_PROGRAM_CLEAR';

export const UNPUBLISH_INTERNAL_PROGRAM = 'UNPUBLISH_INTERNAL_PROGRAM';
export const UNPUBLISH_INTERNAL_PROGRAM_SUCCESS = 'UNPUBLISH_INTERNAL_PROGRAM_SUCCESS';
export const UNPUBLISH_INTERNAL_PROGRAM_FAILURE = 'UNPUBLISH_INTERNAL_PROGRAM_FAILURE';
export const UNPUBLISH_INTERNAL_PROGRAM_CLEAR = 'UNPUBLISH_INTERNAL_PROGRAM_CLEAR';

export const BATCH_ADD_TERM = 'BATCH_ADD_TERM';
export const BATCH_ADD_TERM_SUCCESS = 'BATCH_ADD_TERM_SUCCESS';
export const BATCH_ADD_TERM_FAILURE = 'BATCH_ADD_TERM_FAILURE';
export const BATCH_ADD_TERM_CLEAR = 'BATCH_ADD_TERM_CLEAR';

export const FETCH_PROGRAM_TERMS_REQUEST = 'FETCH_PROGRAM_TERMS_REQUEST';
export const FETCH_PROGRAM_TERMS_SUCCESS = 'FETCH_PROGRAM_TERMS_SUCCESS';
export const FETCH_PROGRAM_TERMS_FAILURE = 'FETCH_PROGRAM_TERMS_FAILURE';
export const FETCH_PROGRAM_TERMS_CLEAR = 'FETCH_PROGRAM_TERMS_CLEAR';

export const BATCH_ADD_APPLICATION = 'BATCH_ADD_APPLICATION';
export const BATCH_ADD_APPLICATION_SUCCESS = 'BATCH_ADD_APPLICATION_SUCCESS';
export const BATCH_ADD_APPLICATION_FAILURE = 'BATCH_ADD_APPLICATION_FAILURE';
export const BATCH_ADD_APPLICATION_CLEAR = 'BATCH_ADD_APPLICATION_CLEAR';

export const BATCH_ADD_FORM = 'BATCH_ADD_FORM';
export const BATCH_ADD_FORM_SUCCESS = 'BATCH_ADD_FORM_SUCCESS';
export const BATCH_ADD_FORM_FAILURE = 'BATCH_ADD_FORM_FAILURE';
export const BATCH_ADD_FORM_CLEAR = 'BATCH_ADD_FORM_CLEAR';

export const AUTHORIZE_EXTERNAL_PROGRAM = 'AUTHORIZE_EXTERNAL_PROGRAM';
export const AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS = 'AUTHORIZE_EXTERNAL_PROGRAM_SUCCESS';
export const AUTHORIZE_EXTERNAL_PROGRAM_FAILURE = 'AUTHORIZE_EXTERNAL_PROGRAM_FAILURE';
export const AUTHORIZE_EXTERNAL_PROGRAM_CLEAR = 'AUTHORIZE_EXTERNAL_PROGRAM_CLEAR';

export const UNAUTHORIZE_EXTERNAL_PROGRAM = 'UNAUTHORIZE_EXTERNAL_PROGRAM';
export const UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS = 'UNAUTHORIZE_EXTERNAL_PROGRAM_SUCCESS';
export const UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE = 'UNAUTHORIZE_EXTERNAL_PROGRAM_FAILURE';
export const UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR = 'UNAUTHORIZE_EXTERNAL_PROGRAM_CLEAR';

export const FETCH_TRAVELERS_REQUEST = 'FETCH_TRAVELERS_REQUEST';
export const FETCH_TRAVELERS_SUCCESS = 'FETCH_TRAVELERS_SUCCESS';
export const FETCH_TRAVELERS_FAILURE = 'FETCH_TRAVELERS_FAILURE';
export const FETCH_TRAVELERS_CLEAR = 'FETCH_TRAVELERS_CLEAR';

export const BATCH_UPDATE_CUSTOM_FIELD = 'BATCH_UPDATE_CUSTOM_FIELD';
export const BATCH_UPDATE_CUSTOM_FIELD_SUCCESS = 'BATCH_UPDATE_CUSTOM_FIELD_SUCCESS';
export const BATCH_UPDATE_CUSTOM_FIELD_CLEAR = 'BATCH_UPDATE_CUSTOM_FIELD_CLEAR';
export const BATCH_UPDATE_CUSTOM_FIELD_FAILURE = 'BATCH_UPDATE_CUSTOM_FIELD_CLEAR';

export const BATCH_ADD_MESSAGE_RECIPIENT = 'BATCH_ADD_MESSAGE_RECIPIENT';
export const BATCH_ADD_MESSAGE_RECIPIENT_CLEAR = 'BATCH_ADD_MESSAGE_RECIPIENT_CLEAR';
export const BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS = 'BATCH_ADD_MESSAGE_RECIPIENT_SUCCESS';
export const BATCH_ADD_MESSAGE_RECIPIENT_FAILURE = 'BATCH_ADD_MESSAGE_RECIPIENT_FAILURE';

export const BATCH_REMOVE_MESSAGE_RECIPIENT = 'BATCH_REMOVE_MESSAGE_RECIPIENT';
export const BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR = 'BATCH_REMOVE_MESSAGE_RECIPIENT_CLEAR';
export const BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE = 'BATCH_REMOVE_MESSAGE_RECIPIENT_FAILURE';
export const BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS = 'BATCH_REMOVE_MESSAGE_RECIPIENT_SUCCESS';

export const BATCH_INVITE_TRAVELERS_TO_EVENT = 'BATCH_INVITE_TRAVELERS_TO_EVENT';
export const BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS = 'BATCH_INVITE_TRAVELERS_TO_EVENT_SUCCESS';
export const BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE = 'BATCH_INVITE_TRAVELERS_TO_EVENT_FAILURE';
export const BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR = 'BATCH_INVITE_TRAVELERS_TO_EVENT_CLEAR';

export const FETCH_TRAVELER_PROGRAMS_REQUEST = 'FETCH_TRAVELER_PROGRAMS_REQUEST';
export const FETCH_TRAVELER_PROGRAMS_SUCCESS = 'FETCH_TRAVELER_PROGRAMS_SUCCESS';
export const FETCH_TRAVELER_PROGRAMS_FAILURE = 'FETCH_TRAVELER_PROGRAMS_FAILURE';
export const FETCH_TRAVELER_PROGRAMS_CLEAR = 'FETCH_TRAVELER_PROGRAMS_CLEAR';

export const FETCH_HORIZONS_BROWN_REQUEST = 'FETCH_HORIZONS_BROWN_REQUEST';
export const FETCH_HORIZONS_BROWN_SUCCESS = 'FETCH_HORIZONS_BROWN_SUCCESS';
export const FETCH_HORIZONS_BROWN_FAILURE = 'FETCH_HORIZONS_BROWN_FAILURE';
export const FETCH_HORIZONS_BROWN_CLEAR = 'FETCH_HORIZONS_BROWN_CLEAR';

export const FETCH_SFSU_DATA_REQUEST = 'FETCH_SFSU_DATA_REQUEST';
export const FETCH_SFSU_DATA_SUCCESS = 'FETCH_SFSU_DATA_SUCCESS';
export const FETCH_SFSU_DATA_FAILURE = 'FETCH_SFSU_DATA_FAILURE';
export const FETCH_SFSU_DATA_CLEAR = 'FETCH_SFSU_DATA_CLEAR';

/*
=======================
Applications - 2021
=======================
*/

export const APPLICATION_STATUS_UPDATE_REQUEST = 'APPLICATION_STATUS_UPDATE_REQUEST';
export const APPLICATION_STATUS_UPDATE_SUCCESS = 'APPLICATION_STATUS_UPDATE_SUCCESS';
export const APPLICATION_STATUS_UPDATE_FAILURE = 'APPLICATION_STATUS_UPDATE_FAILURE';
export const APPLICATION_STATUS_UPDATE_CLEAR = 'APPLICATION_STATUS_UPDATE_CLEAR';
export const TRAVELER_FORMS_REQUEST = 'TRAVELER_FORMS_REQUEST';
export const TRAVELER_FORMS_SUCCESS = 'TRAVELER_FORMS_SUCCESS';
export const TRAVELER_FORMS_FAILURE = 'TRAVELER_FORMS_FAILURE';
export const TRAVELER_FORMS_CLEAR = 'TRAVELER_FORMS_CLEAR';
export const TRAVELER_FORMS_TEMPLATE_REQUEST = 'TRAVELER_FORMS_TEMPLATE_REQUEST';
export const TRAVELER_FORMS_TEMPLATE_SUCCESS = 'TRAVELER_FORMS_TEMPLATE_SUCCESS';
export const TRAVELER_FORMS_TEMPLATE_FAILURE = 'TRAVELER_FORMS_TEMPLATE_FAILURE';
export const TRAVELER_FORMS_TEMPLATE_CLEAR = 'TRAVELER_FORMS_TEMPLATE_CLEAR';

/*
=======================
Forms - 2021
=======================
*/

export const FORM_STATUS_UPDATE_REQUEST = 'FORM_STATUS_UPDATE_REQUEST';
export const FORM_STATUS_UPDATE_SUCCESS = 'FORM_STATUS_UPDATE_SUCCESS';
export const FORM_STATUS_UPDATE_FAILURE = 'FORM_STATUS_UPDATE_FAILURE';
export const FORM_STATUS_UPDATE_CLEAR = 'FORM_STATUS_UPDATE_CLEAR';
export const BATCH_ADD_FORMS_TO_TRAVELERS = 'BATCH_ADD_FORMS_TO_TRAVELERS';
export const UPDATE_FORM_ON_CLIENT = 'UPDATE_FORM_ON_CLIENT';
export const BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS = 'BATCH_ADD_FORMS_TO_TRAVELERS_SUCCESS';
export const BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE = 'BATCH_ADD_FORMS_TO_TRAVELERS_FAILURE';
export const BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR = 'BATCH_ADD_FORMS_TO_TRAVELERS_CLEAR';

/*
=======================
Org Settings - 2021
=======================
*/
export const BATCH_ADD_AUTH_TERM = 'BATCH_ADD_AUTH_TERM';
export const BATCH_ADD_AUTH_TERM_SUCCESS = 'BATCH_ADD_AUTH_TERM_SUCCESS';
export const BATCH_ADD_AUTH_TERM_FAILURE = 'BATCH_ADD_AUTH_TERM_FAILURE';
export const BATCH_ADD_AUTH_TERM_CLEAR = 'BATCH_ADD_AUTH_TERM_CLEAR';
export const GET_AUTH_TERMS = 'GET_AUTH_TERMS';
export const BATCH_UPDATE_AUTH_TERM = 'BATCH_UPDATE_AUTH_TERM';
export const BATCH_UPDATE_AUTH_TERM_SUCCESS = 'BATCH_UPDATE_AUTH_TERM_SUCCESS';
export const BATCH_UPDATE_AUTH_TERM_FAILURE = 'BATCH_UPDATE_AUTH_TERM_FAILURE';
export const BATCH_UPDATE_AUTH_TERM_CLEAR = 'BATCH_UPDATE_AUTH_TERM_CLEAR';
export const GET_AUTH_PROGRAM_RANGES = 'GET_AUTH_PROGRAM_RANGES';
export const GET_FORM_TEMPLATES = 'GET_FORM_TEMPLATES';
export const GET_APPLICATION_TEMPLATES = 'GET_APPLICATION_TEMPLATES';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_CLEAR = 'CHANGE_PASSWORD_CLEAR';

/*
=======================
Term Names
=======================
*/
export const GET_TERM_NAMES = 'GET_TERM_NAMES';
export const GET_TERM_NAMES_ACTIVE = 'GET_TERM_NAMES_ACTIVE';
export const CREATE_TERM_NAME_REQUEST = 'CREATE_TERM_NAME_REQUEST';
export const CREATE_TERM_NAME_SUCCESS = 'CREATE_TERM_NAME_SUCCESS';
export const CREATE_TERM_NAME_FAILURE = 'CREATE_TERM_NAME_FAILURE';
export const CREATE_TERM_NAME_CLEAR = 'CREATE_TERM_NAME_CLEAR';
export const EDIT_TERM_NAME_REQUEST = 'EDIT_TERM_NAME_REQUEST';
export const EDIT_TERM_NAME_SUCCESS = 'EDIT_TERM_NAME_SUCCESS';
export const EDIT_TERM_NAME_FAILURE = 'EDIT_TERM_NAME_FAILURE';
export const EDIT_TERM_NAME_CLEAR = 'EDIT_TERM_NAME_CLEAR';
export const BATCH_ADD_TERM_NAME_AUTH = 'BATCH_ADD_TERM_NAME_AUTH';
export const BATCH_ADD_TERM_NAME_AUTH_SUCCESS = 'BATCH_ADD_TERM_NAME_AUTH_SUCCESS';
export const BATCH_ADD_TERM_NAME_AUTH_CLEAR = 'BATCH_ADD_TERM_NAME_AUTH_CLEAR';
export const BATCH_ADD_TERM_NAME_AUTH_FAILURE = 'BATCH_ADD_TERM_NAME_AUTH_FAILURE';

/*
=======================
Public Visitor
=======================
*/
export const GET_VISITOR_PROGRAMS_REQUEST = 'GET_VISITOR_PROGRAMS_REQUEST';
export const GET_VISITOR_PROGRAMS_SUCCESS = 'GET_VISITOR_PROGRAMS_SUCCESS';
export const GET_VISITOR_PROGRAMS_FAILURE = 'GET_VISITOR_PROGRAMS_FAILURE';
export const GET_VISITOR_PROGRAMS_CLEAR = 'GET_VISITOR_PROGRAMS_CLEAR';

/*
================================
Program Modify/New/Delete/Get
================================
*/
export const CREATE_NEW_PROGRAM_CLEAR = 'CREATE_NEW_PROGRAM_CLEAR';
export const CREATE_NEW_PROGRAM_REQUEST = 'CREATE_NEW_PROGRAM_REQUEST';
export const CREATE_NEW_PROGRAM_SUCCESS = 'CREATE_NEW_PROGRAM_SUCCESS';
export const CREATE_NEW_PROGRAM_FAILURE = 'CREATE_NEW_PROGRAM_FAILURE';

export const DELETE_DRAFT_PROGRAM_REQUEST = 'DELETE_DRAFT_PROGRAM_REQUEST';
export const DELETE_DRAFT_PROGRAM_SUCCESS = 'DELETE_DRAFT_PROGRAM_SUCCESS';
export const DELETE_DRAFT_PROGRAM_FAILURE = 'DELETE_DRAFT_PROGRAM_FAILURE';

export const GET_PROGRAM_REQUEST = 'GET_PROGRAM_REQUEST';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_FAILURE = 'GET_PROGRAM_FAILURE';

export const MODIFY_PROGRAM_CLEAR = 'MODIFY_PROGRAM_CLEAR';
export const MODIFY_PROGRAM_REQUEST = 'MODIFY_PROGRAM_REQUEST';
export const MODIFY_PROGRAM_SUCCESS = 'MODIFY_PROGRAM_SUCCESS';
export const MODIFY_PROGRAM_FAILURE = 'MODIFY_PROGRAM_FAILURE';

export const ARCHIVE_PROGRAM_SUCCESS = 'ARCHIVE_PROGRAM_SUCCESS';
export const ARCHIVE_PROGRAM_REQUEST = 'ARCHIVE_PROGRAM_REQUEST';
export const ARCHIVE_PROGRAM_CLEAR = 'ARCHIVE_PROGRAM_CLEAR';
export const ARCHIVE_PROGRAM_FAILURE = 'ARCHIVE_PROGRAM_FAILURE';


export const ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST = 'ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST';
export const ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS = 'ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS';
export const ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE = 'ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE';
export const ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR = 'ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR';

export const BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST = 'BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_REQUEST';
export const BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS = 'BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_SUCCESS';
export const BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE = 'BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_FAILURE';
export const BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR = 'BATCH_ADD_EDIT_PROGRAM_BROCHURE_SECTION_CLEAR';

export const BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST = 'BATCH_DELETE_PROGRAM_BROCHURE_SECTION_REQUEST';
export const BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS = 'BATCH_DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS';
export const BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE = 'BATCH_DELETE_PROGRAM_BROCHURE_SECTION_FAILURE';
export const BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR = 'BATCH_DELETE_PROGRAM_BROCHURE_SECTION_CLEAR';

export const DELETE_PROGRAM_BROCHURE_SECTION_REQUEST = 'DELETE_PROGRAM_BROCHURE_SECTION_REQUEST';
export const DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS = 'DELETE_PROGRAM_BROCHURE_SECTION_SUCCESS';
export const DELETE_PROGRAM_BROCHURE_SECTION_FAILURE = 'DELETE_PROGRAM_BROCHURE_SECTION_FAILURE';
export const DELETE_PROGRAM_BROCHURE_SECTION_CLEAR = 'DELETE_PROGRAM_BROCHURE_SECTION_CLEAR';

/*
================================
Authentication V2 Actions
================================
*/
export const CHECK_EMAIL_CLEAR = 'CHECK_EMAIL_CLEAR';
export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILURE = 'CHECK_EMAIL_FAILURE';
export const RESET_PASSWORD_REQUEST_CLEAR = 'RESET_PASSWORD_REQUEST_CLEAR';
export const RESET_PASSWORD_REQUEST_REQUEST = 'RESET_PASSWORD_REQUEST_REQUEST';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';
export const GET_USER_FROM_RESET_TOKEN_CLEAR = 'GET_USER_FROM_RESET_TOKEN_CLEAR';
export const GET_USER_FROM_RESET_TOKEN_REQUEST = 'GET_USER_FROM_RESET_TOKEN_REQUEST';
export const GET_USER_FROM_RESET_TOKEN_SUCCESS = 'GET_USER_FROM_RESET_TOKEN_SUCCESS';
export const GET_USER_FROM_RESET_TOKEN_FAILURE = 'GET_USER_FROM_RESET_TOKEN_FAILURE';
export const TOKEN_CHANGE_PASSWORD_CLEAR = 'TOKEN_CHANGE_PASSWORD_CLEAR';
export const TOKEN_CHANGE_PASSWORD_REQUEST = 'TOKEN_CHANGE_PASSWORD_REQUEST';
export const TOKEN_CHANGE_PASSWORD_SUCCESS = 'TOKEN_CHANGE_PASSWORD_SUCCESS';
export const TOKEN_CHANGE_PASSWORD_FAILURE = 'TOKEN_CHANGE_PASSWORD_FAILURE';
export const CREATE_USER_ACCOUNT_CLEAR = 'CREATE_USER_ACCOUNT_CLEAR';
export const CREATE_USER_ACCOUNT_REQUEST = 'CREATE_USER_ACCOUNT_REQUEST';
export const CREATE_USER_ACCOUNT_SUCCESS = 'CREATE_USER_ACCOUNT_SUCCESS';
export const CREATE_USER_ACCOUNT_FAILURE = 'CREATE_USER_ACCOUNT_FAILURE';
export const SELECT_USER_ROLE_CLEAR = 'SELECT_USER_ROLE_CLEAR';
export const SELECT_USER_ROLE_REQUEST = 'SELECT_USER_ROLE_REQUEST';
export const SELECT_USER_ROLE_SUCCESS = 'SELECT_USER_ROLE_SUCCESS';
export const SELECT_USER_ROLE_FAILURE = 'SELECT_USER_ROLE_FAILURE';

/*
================================
Forms 2022
================================
*/
export const FORMS_LIST_CLEAR = 'FORMS_LIST_CLEAR';
export const FORMS_LIST_REQUEST = 'FORMS_LIST_REQUEST';
export const FORMS_LIST_SUCCESS = 'FORMS_LIST_SUCCESS';
export const FORMS_LIST_FAILURE = 'FORMS_LIST_FAILURE';
export const CREATE_FORM_CLEAR = 'CREATE_FORM_CLEAR';
export const CREATE_FORM_REQUEST = 'CREATE_FORM_REQUEST';
export const CREATE_FORM_SUCCESS = 'CREATE_FORM_SUCCESS';
export const CREATE_FORM_FAILURE = 'CREATE_FORM_FAILURE';
export const FETCH_QUESTION_TYPES_CLEAR = 'FETCH_QUESTION_TYPES_CLEAR';
export const FETCH_QUESTION_TYPES_REQUEST = 'FETCH_QUESTION_TYPES_REQUEST';
export const FETCH_QUESTION_TYPES_SUCCESS = 'FETCH_QUESTION_TYPES_SUCCESS';
export const FETCH_QUESTION_TYPES_FAILURE = 'FETCH_QUESTION_TYPES_FAILURE';
export const FETCH_FORM_CLEAR = 'FETCH_FORM_CLEAR';
export const FETCH_FORM_REQUEST = 'FETCH_FORM_REQUEST';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_FAILURE = 'FETCH_FORM_FAILURE';
export const UPDATE_FORM_CLEAR = 'UPDATE_FORM_CLEAR';
export const UPDATE_FORM_REQUEST = 'UPDATE_FORM_REQUEST';
export const UPDATE_FORM_SUCCESS = 'UPDATE_FORM_SUCCESS';
export const UPDATE_FORM_FAILURE = 'UPDATE_FORM_FAILURE';
export const UPDATE_FORM_STATUS_CLEAR = 'UPDATE_FORM_STATUS_CLEAR';
export const UPDATE_FORM_STATUS_REQUEST = 'UPDATE_FORM_STATUS_REQUEST';
export const UPDATE_FORM_STATUS_SUCCESS = 'UPDATE_FORM_STATUS_SUCCESS';
export const UPDATE_FORM_STATUS_FAILURE = 'UPDATE_FORM_STATUS_FAILURE';
export const UPDATE_BUILDER_FORM_STATUS_SUCCESS = 'UPDATE_BUILDER_FORM_STATUS_SUCCESS';
export const FAKE_FORM_UPDATE = 'FAKE_FORM_UPDATE';
export const UPDATE_QUESTION_CLEAR = 'UPDATE_QUESTION_CLEAR';
export const UPDATE_QUESTION_REQUEST = 'UPDATE_QUESTION_REQUEST';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE';
export const DUPLICATE_FORM_CLEAR = 'DUPLICATE_FORM_CLEAR';
export const DUPLICATE_FORM_REQUEST = 'DUPLICATE_FORM_REQUEST';
export const DUPLICATE_FORM_SUCCESS = 'DUPLICATE_FORM_SUCCESS';
export const DUPLICATE_FORM_FAILURE = 'DUPLICATE_FORM_FAILURE';
export const DELETE_FORM_CLEAR = 'DELETE_FORM_CLEAR';
export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAILURE = 'DELETE_FORM_FAILURE';
export const UPDATE_LAYOUT_CLEAR = 'UPDATE_LAYOUT_CLEAR';
export const UPDATE_LAYOUT_REQUEST = 'UPDATE_LAYOUT_REQUEST';
export const UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
export const UPDATE_LAYOUT_FAILURE = 'UPDATE_LAYOUT_FAILURE';
export const DELETE_QUESTION_CLEAR = 'DELETE_QUESTION_CLEAR';
export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';
export const UPDATE_FORM_NAME_CLEAR = 'UPDATE_FORM_NAME_CLEAR';
export const UPDATE_FORM_NAME_REQUEST = 'UPDATE_FORM_NAME_REQUEST';
export const UPDATE_FORM_NAME_SUCCESS = 'UPDATE_FORM_NAME_SUCCESS';
export const UPDATE_FORM_NAME_FAILURE = 'UPDATE_FORM_NAME_FAILURE';
export const FETCH_TRAVELER_SUBMISSION_REQUEST = 'FETCH_TRAVELER_SUBMISSION_REQUEST';
export const FETCH_TRAVELER_SUBMISSION_SUCCESS = 'FETCH_TRAVELER_SUBMISSION_SUCCESS';
export const FETCH_TRAVELER_SUBMISSION_FAILURE = 'FETCH_TRAVELER_SUBMISSION_FAILURE';
export const FETCH_TRAVELER_SUBMISSION_CLEAR = 'FETCH_TRAVELER_SUBMISSION_CLEAR';
export const PATCH_TRAVELER_FORM_REQUEST = 'PATCH_TRAVELER_FORM_REQUEST';
export const PATCH_TRAVELER_FORM_SUCCESS = 'PATCH_TRAVELER_FORM_SUCCESS';
export const PATCH_TRAVELER_FORM_FAILURE = 'PATCH_TRAVELER_FORM_FAILURE';
export const PATCH_TRAVELER_FORM_CLEAR = 'PATCH_TRAVELER_FORM_CLEAR';
export const FETCH_SUBMISSION_STATUSES_REQUEST = 'FETCH_SUBMISSION_STATUSES_REQUEST';
export const FETCH_SUBMISSION_STATUSES_SUCCESS = 'FETCH_SUBMISSION_STATUSES_SUCCESS';
export const FETCH_SUBMISSION_STATUSES_FAILURE = 'FETCH_SUBMISSION_STATUSES_FAILURE';
export const FETCH_SUBMISSION_STATUSES_CLEAR = 'FETCH_SUBMISSION_STATUSES_CLEAR';
export const FETCH_VISITOR_SUBMISSION_REQUEST = 'FETCH_VISITOR_SUBMISSION_REQUEST';
export const FETCH_VISITOR_SUBMISSION_SUCCESS = 'FETCH_VISITOR_SUBMISSION_SUCCESS';
export const FETCH_VISITOR_SUBMISSION_FAILURE = 'FETCH_VISITOR_SUBMISSION_FAILURE';
export const FETCH_VISITOR_SUBMISSION_CLEAR = 'FETCH_VISITOR_SUBMISSION_CLEAR';
export const PATCH_VISITOR_SUBMISSION_REQUEST = 'PATCH_VISITOR_SUBMISSION_REQUEST';
export const PATCH_VISITOR_SUBMISSION_SUCCESS = 'PATCH_VISITOR_SUBMISSION_SUCCESS';
export const PATCH_VISITOR_SUBMISSION_FAILURE = 'PATCH_VISITOR_SUBMISSION_FAILURE';
export const PATCH_VISITOR_SUBMISSION_CLEAR = 'PATCH_VISITOR_SUBMISSION_CLEAR';
export const FETCH_TRAVELER_ALTERNATE_INFO_REQUEST = 'FETCH_TRAVELER_ALTERNATE_INFO_REQUEST';
export const FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS = 'FETCH_TRAVELER_ALTERNATE_INFO_SUCCESS';
export const FETCH_TRAVELER_ALTERNATE_INFO_FAILURE = 'FETCH_TRAVELER_ALTERNATE_INFO_FAILURE';
export const FETCH_TRAVELER_ALTERNATE_INFO_CLEAR = 'FETCH_TRAVELER_ALTERNATE_INFO_CLEAR';
export const POST_SEND_RECOMMENDATION_MAILER_REQUEST = 'POST_SEND_RECOMMENDATION_MAILER_REQUEST';
export const POST_SEND_RECOMMENDATION_MAILER_SUCCESS = 'POST_SEND_RECOMMENDATION_MAILER_SUCCESS';
export const POST_SEND_RECOMMENDATION_MAILER_FAILURE = 'POST_SEND_RECOMMENDATION_MAILER_FAILURE';
export const POST_SEND_RECOMMENDATION_MAILER_CLEAR = 'POST_SEND_RECOMMENDATION_MAILER_CLEAR';
export const CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST = 'CANCEL_SEND_RECOMMENDATION_MAILER_REQUEST';
export const CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS = 'CANCEL_SEND_RECOMMENDATION_MAILER_SUCCESS';
export const CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE = 'CANCEL_SEND_RECOMMENDATION_MAILER_FAILURE';
export const CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR = 'CANCEL_SEND_RECOMMENDATION_MAILER_CLEAR';
export const RESEND_RECOMMENDATION_MAILER_REQUEST = 'RESEND_RECOMMENDATION_MAILER_REQUEST';
export const RESEND_RECOMMENDATION_MAILER_SUCCESS = 'RESEND_RECOMMENDATION_MAILER_SUCCESS';
export const RESEND_RECOMMENDATION_MAILER_FAILURE = 'RESEND_RECOMMENDATION_MAILER_FAILURE';
export const RESEND_RECOMMENDATION_MAILER_CLEAR = 'RESEND_RECOMMENDATION_MAILER_CLEAR';
export const UPDATE_FORM_SUBMISSION_RESPONSE = 'UPDATE_FORM_SUBMISSION_RESPONSE';
export const UPLOAD_FILE_ADD_REQUEST = 'UPLOAD_FILE_ADD_REQUEST';
export const UPLOAD_FILE_ADD_SUCCESS = 'UPLOAD_FILE_ADD_SUCCESS';
export const UPLOAD_FILE_ADD_FAILURE = 'UPLOAD_FILE_ADD_FAILURE';
export const UPLOAD_FILE_ADD_CLEAR = 'UPLOAD_FILE_ADD_CLEAR';
export const UPLOAD_FILE_DELETE_REQUEST = 'UPLOAD_FILE_DELETE_REQUEST';
export const UPLOAD_FILE_DELETE_SUCCESS = 'UPLOAD_FILE_DELETE_SUCCESS';
export const UPLOAD_FILE_DELETE_FAILURE = 'UPLOAD_FILE_DELETE_FAILURE';
export const UPLOAD_FILE_DELETE_CLEAR = 'UPLOAD_FILE_DELETE_CLEAR';
export const PAYMENT_STATUS_OPTIONS_REQUEST = 'PAYMENT_STATUS_OPTIONS_REQUEST';
export const PAYMENT_STATUS_OPTIONS_SUCCESS = 'PAYMENT_STATUS_OPTIONS_SUCCESS';
export const PAYMENT_STATUS_OPTIONS_FAILURE = 'PAYMENT_STATUS_OPTIONS_FAILURE';
export const PAYMENT_STATUS_OPTIONS_CLEAR = 'PAYMENT_STATUS_OPTIONS_CLEAR';
export const PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN = 'PAYMENT_STATUS_FLYWIRE_UPDATE_ADMIN';
export const PAYMENT_STATUS_FLYWIRE_UPDATE = 'PAYMENT_STATUS_FLYWIRE_UPDATE';
export const UPDATE_ALL_PAYMENTS_STATUS_ADMIN = 'UPDATE_ALL_PAYMENTS_STATUS_ADMIN';
export const FETCH_TRAVELER_INFO_FIELDS_REQUEST = 'FETCH_TRAVELER_INFO_FIELDS_REQUEST';
export const FETCH_TRAVELER_INFO_SUCCESS = 'FETCH_TRAVELER_INFO_FIELDS_SUCCESS';
export const FETCH_TRAVELER_INFO_FIELDS_FAILURE = 'FETCH_TRAVELER_INFO_FIELDS_FAILURE';
export const FETCH_TRAVELER_INFO_FIELDS_CLEAR = 'FETCH_TRAVELER_INFO_FIELDS_CLEAR';
export const ADMIN_ADD_FORMS_REQUEST = 'ADMIN_ADD_FORMS_REQUEST';
export const ADMIN_ADD_FORMS_SUCCESS = 'ADMIN_ADD_FORMS_SUCCESS';
export const ADMIN_ADD_FORMS_FAILURE = 'ADMIN_ADD_FORMS_FAILURE';
export const ADMIN_ADD_FORMS_CLEAR = 'ADMIN_ADD_FORMS_CLEAR';
export const DELETE_TRAVELER_APPLICATION_CLEAR = 'DELETE_TRAVELER_APPLICATION_CLEAR';
export const DELETE_TRAVELER_APPLICATION_REQUEST = 'DELETE_TRAVELER_APPLICATION_REQUEST';
export const DELETE_TRAVELER_APPLICATION_FAILURE = 'DELETE_TRAVELER_APPLICATION_FAILURE';
export const DELETE_TRAVELER_APPLICATION_SUCCESS = 'DELETE_TRAVELER_APPLICATION_SUCCESS';
export const ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR = 'ADD_FORMS_TO_TRAVELER_PROFILE_CLEAR';
export const ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST = 'ADD_FORMS_TO_TRAVELER_PROFILE_REQUEST';
export const ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE = 'ADD_FORMS_TO_TRAVELER_PROFILE_FAILURE';
export const ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS = 'ADD_FORMS_TO_TRAVELER_PROFILE_SUCCESS';
export const ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR = 'ADD_APPLICATION_TO_TRAVELER_PROFILE_CLEAR';
export const ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST = 'ADD_APPLICATION_TO_TRAVELER_PROFILE_REQUEST';
export const ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE = 'ADD_APPLICATION_TO_TRAVELER_PROFILE_FAILURE';
export const ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS = 'ADD_APPLICATION_TO_TRAVELER_PROFILE_SUCCESS';
export const GET_AVAILABLE_FORMS_CLEAR = 'GET_AVAILABLE_FORMS_CLEAR';
export const GET_AVAILABLE_FORMS_REQUEST = ' GET_AVAILABLE_FORMS_REQUEST';
export const GET_AVAILABLE_FORMS_FAILURE = ' GET_AVAILABLE_FORMS_FAILURE';
export const GET_AVAILABLE_FORMS_SUCCESS = ' GET_AVAILABLE_FORMS_SUCCESS';
export const FETCH_AVAILABLE_APPLICATIONS_REQUEST = 'FETCH_AVAILABLE_APPLICATIONS_REQUEST';
export const FETCH_AVAILABLE_APPLICATIONS_CLEAR = 'FETCH_AVAILABLE_APPLICATIONS_CLEAR';
export const FETCH_AVAILABLE_APPLICATIONS_FAILURE = 'FETCH_AVAILABLE_APPLICATIONS_FAILURE';
export const FETCH_AVAILABLE_APPLICATIONS_SUCCESS = 'FETCH_AVAILABLE_APPLICATIONS_SUCCESS';
export const FETCH_AVAILABLE_ALTERNATES_REQUEST = 'FETCH_AVAILABLE_ALTERNATES_REQUEST';
export const FETCH_AVAILABLE_ALTERNATES_CLEAR = 'FETCH_AVAILABLE_ALTERNATES_CLEAR';
export const FETCH_AVAILABLE_ALTERNATES_FAILURE = 'FETCH_AVAILABLE_ALTERNATES_FAILURE';
export const FETCH_AVAILABLE_ALTERNATES_SUCCESS = 'FETCH_AVAILABLE_ALTERNATES_SUCCESS';

export const FETCH_FORM_STATUS_OPTIONS_REQUEST = 'FETCH_FORM_STATUS_OPTIONS_REQUEST';
export const FETCH_FORM_STATUS_OPTIONS_CLEAR = 'FETCH_FORM_STATUS_OPTIONS_CLEAR';
export const FETCH_FORM_STATUS_OPTIONS_FAILURE = 'FETCH_FORM_STATUS_OPTIONS_FAILURE';
export const FETCH_FORM_STATUS_OPTIONS_SUCCESS = 'FETCH_FORM_STATUS_OPTIONS_SUCCESS';
export const FETCH_APP_STATUS_OPTIONS_REQUEST = 'FETCH_APP_STATUS_OPTIONS_REQUEST';
export const FETCH_APP_STATUS_OPTIONS_CLEAR = 'FETCH_APP_STATUS_OPTIONS_CLEAR';
export const FETCH_APP_STATUS_OPTIONS_FAILURE = 'FETCH_APP_STATUS_OPTIONS_FAILURE';
export const FETCH_APP_STATUS_OPTIONS_SUCCESS = 'FETCH_APP_STATUS_OPTIONS_SUCCESS';
export const SET_ALTERNATE_SAVE_ERROR = 'SET_ALTERNATE_SAVE_ERROR';

export const FETCH_RISK_LEVELS_REQUEST = 'FETCH_RISK_LEVELS_REQUEST';
export const SET_RISK_LEVELS = 'SET_RISK_LEVELS';
export const FETCH_RISK_LEVELS_CLEAR = 'FETCH_RISK_LEVELS_CLEAR';
export const FETCH_RISK_LEVELS_FAILURE = 'FETCH_RISK_LEVELS_FAILURE';

export const FETCH_RISK_TYPES_REQUEST = 'FETCH_RISK_TYPES_REQUEST';
export const SET_RISK_TYPES = 'SET_RISK_TYPES';
export const FETCH_RISK_TYPES_CLEAR = 'FETCH_RISK_TYPES_CLEAR';
export const FETCH_RISK_TYPES_FAILURE = 'FETCH_RISK_TYPES_FAILURE';

export const SAVE_FILTER_REQUEST = 'SAVE_FILTER_REQUEST';
export const SAVE_FILTER_FAILURE = 'SAVE_FILTER_FAILURE';
export const SAVE_FILTER_CLEAR = 'SAVE_FILTER_CLEAR';

export const FETCH_FILTER_CONFIG_REQUEST = 'FETCH_FILTER_CONFIG_REQUEST';
export const SET_FILTER_CONFIG = 'SET_FILTER_CONFIG';
export const FETCH_FILTER_CONFIG_CLEAR = 'FETCH_FILTER_CONFIG_CLEAR';
export const FETCH_FILTER_CONFIG_FAILURE = 'FETCH_FILTER_CONFIG_FAILURE';

export const TOGGLE_SEND_MESSAGE_DIALOG = 'TOGGLE_SEND_MESSAGE_DIALOG';
export const TOGGLE_SEND_RISK_ALERT_DIALOG = 'TOGGLE_SEND_RISK_ALERT_DIALOG';

export const FETCH_RISK_ALERTS_REQUEST = 'FETCH_RISK_ALERTS_REQUEST';
export const SET_RISK_ALERTS = 'SET_RISK_ALERTS';
export const FETCH_RISK_ALERTS_CLEAR = 'FETCH_RISK_ALERTS_CLEAR';
export const FETCH_RISK_ALERTS_FAILURE = 'FETCH_RISK_ALERTS_FAILURE';


// risk alert notes
export const CREATE_RISK_ALERTS_NOTE_REQUEST = 'CREATE_RISK_ALERTS_NOTE_REQUEST';
export const CREATE_RISK_ALERTS_NOTE_SUCCESS = 'CREATE_RISK_ALERTS_NOTE_SUCCESS';
export const CREATE_RISK_ALERTS_NOTE_CLEAR = 'CREATE_RISK_ALERTS_NOTE_CLEAR';
export const CREATE_RISK_ALERTS_NOTE_FAILURE = 'CREATE_RISK_ALERTS_NOTE_FAILURE';

export const UPDATE_RISK_ALERTS_NOTE_REQUEST = 'UPDATE_RISK_ALERTS_NOTE_REQUEST';
export const UPDATE_RISK_ALERTS_NOTE_SUCCESS = 'UPDATE_RISK_ALERTS_NOTE_SUCCESS';
export const UPDATE_RISK_ALERTS_NOTE_CLEAR = 'UPDATE_RISK_ALERTS_NOTE_CLEAR';
export const UPDATE_RISK_ALERTS_NOTE_FAILURE = 'UPDAT_RISK_ALERTS_NOTE_FAILURE';

export const DELETE_RISK_ALERTS_NOTE_REQUEST = 'DELETE_RISK_ALERTS_NOTE_REQUEST';
export const DELETE_RISK_ALERTS_NOTE_SUCCESS = 'DELETE_RISK_ALERTS_NOTE_SUCCESS';
export const DELETE_RISK_ALERTS_NOTE_CLEAR = 'DELETE_RISK_ALERTS_NOTE_CLEAR';
export const DELETE_RISK_ALERTS_NOTE_FAILURE = 'DELETE_RISK_ALERTS_NOTE_FAILURE';



export const FETCH_RISK_DETAIL_REQUEST = 'FETCH_RISK_DETAIL_REQUEST';
export const SET_RISK_DETAIL = 'SET_RISK_DETAIL';
export const FETCH_RISK_DETAIL_CLEAR = 'FETCH_RISK_DETAIL_CLEAR';
export const FETCH_RISK_DETAIL_FAILURE = 'FETCH_RISK_DETAIL_FAILURE';

export const MARK_ALL_READ_REQUEST = 'MARK_ALL_READ_REQUEST';
export const MARK_ALL_READ_FAILURE = 'MARK_ALL_READ_FAILURE';
export const MARK_ALL_READ_CLEAR = 'MARK_ALL_READ_CLEAR';

export const FETCH_RISK_ALERT_HISTORY_REQUEST = 'FETCH_RISK_ALERT_HISTORY_REQUEST';
export const SET_RISK_ALERT_HISTORY = 'SET_RISK_ALERT_HISTORY';
export const FETCH_RISK_ALERT_HISTORY_CLEAR = 'FETCH_RISK_ALERT_HISTORY_CLEAR';
export const FETCH_RISK_ALERT_HISTORY_FAILURE = 'FETCH_RISK_ALERT_HISTORY_FAILURE';

export const FETCH_RISK_ALERT_HISTORY_CSV_REQUEST = 'FETCH_RISK_ALERT_HISTORY_CSV_REQUEST';
export const FETCH_RISK_ALERT_HISTORY_CSV_CLEAR = 'FETCH_RISK_ALERT_HISTORY_CSV_CLEAR';
export const FETCH_RISK_ALERT_HISTORY_CSV_FAILURE = 'FETCH_RISK_ALERT_HISTORY_CSV_FAILURE';
export const SET_RISK_ALERT_HISTORY_CSV_DATA = 'SET_RISK_ALERT_HISTORY_CSV_DATA';

export const FETCH_ALERT_TRAVELERS_REQUEST = 'FETCH_ALERT_TRAVELERS_REQUEST';
export const SET_ALERT_TRAVELERS = 'SET_ALERT_TRAVELERS';
export const FETCH_ALERT_TRAVELERS_CLEAR = 'FETCH_ALERT_TRAVELERS_CLEAR';
export const FETCH_ALERT_TRAVELERS_FAILURE = 'FETCH_ALERT_TRAVELERS_FAILURE';

export const FETCH_COUNTRIES_REQUEST = 'FETCH_COUNTRIES_REQUEST';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const FETCH_COUNTRIES_CLEAR = 'FETCH_COUNTRIES_CLEAR';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_SUCCESS = 'SEND_NOTIFICATION_SUCCESS';
export const SEND_NOTIFICATION_FAILURE = 'SEND_NOTIFICATION_FAILURE';

export const SEND_ALERT_REQUEST = 'SEND_ALERT_REQUEST';
export const SEND_ALERT_SUCCESS = 'SEND_ALERT_SUCCESS';
export const SEND_ALERT_FAILURE = 'SEND_ALERT_FAILURE';

export const FETCH_TRAVELER_ALERT_INFO_REQUEST = 'TRAVELER_ALERT_INFO_REQUEST';
export const SET_TRAVELER_ALERT_INFO = 'SET_TRAVELER_ALERT_INFO';
export const FETCH_TRAVELER_ALERT_INFO_CLEAR = 'FETCH_TRAVELER_ALERT_INFO_CLEAR';
export const FETCH_TRAVELER_ALERT_INFO_FAILURE = 'FETCH_TRAVELER_ALERT_INFO_FAILURE';

export const SEND_CUSTOM_MESSAGE_REQUEST = 'SEND_CUSTOM_MESSAGE_REQUEST';
export const SEND_CUSTOM_MESSAGE_SUCCESS = 'SEND_CUSTOM_MESSAGE_SUCCESS';
export const SEND_CUSTOM_MESSAGE_FAILURE = 'SEND_CUSTOM_MESSAGE_FAILURE';

// Automation

export const FETCH_AUTOMATION_REQUEST = 'FETCH_AUTOMATION_REQUEST';
export const FETCH_AUTOMATION_SUCCESS = 'FETCH_AUTOMATION_SUCCESS';
export const FETCH_AUTOMATION_FAILURE = 'FETCH_AUTOMATION_FAILURE';
export const FETCH_AUTOMATION_CLEAR = 'FETCH_AUTOMATION_CLEAR';
export const FETCH_AUTOMATION_DETAIL_SUCCESS = 'FETCH_AUTOMATION_DETAIL_SUCCESS';
export const FETCH_AUTOMATION_DETAIL_FAILURE = 'FETCH_AUTOMATION_DETAIL_FAILURE';
export const FETCH_AUTOMATION_ACTIONS = 'FETCH_AUTOMATION_ACTIONS';
export const FETCH_AUTOMATION_CONDITIONS = 'FETCH_AUTOMATION_CONDITIONS';
export const FETCH_AUTOMATION_TOKENS = 'FETCH_AUTOMATION_TOKENS';
export const FETCH_AUTOMATION_TYPES = 'FETCH_AUTOMATION_TYPES';
export const FETCH_AUTOMATION_TYPES_REQUEST = 'FETCH_AUTOMATION_TYPES_REQUEST';
export const FETCH_AUTOMATION_TYPES_FAILURE = 'FETCH_AUTOMATION_TYPES_FAILURE';

export const UPDATE_AUTOMATION_DETAIL_SUCCESS = 'UPDATE_AUTOMATION_DETAIL_SUCCESS';
export const UPDATE_AUTOMATION_DETAIL_FAILURE = 'UPDATE_AUTOMATION_DETAIL_FAILURE';
export const UPDATE_AUTOMATION_DETAIL_CLEAR = 'UPDATE_AUTOMATION_DETAIL_CLEAR';
export const FETCH_AUTOMATION_BUTTON_TARGETS = 'FETCH_AUTOMATION_BUTTON_TARGETS';
export const TOGGLE_EMERGENCY_NUMBER_DIALOG = 'TOGGLE_EMERGENCY_NUMBER_DIALOG';

export const MARK_ALL_READ_MESSAGE_REQUEST = 'MARK_ALL_READ_MESSAGE_REQUEST';
export const MARK_ALL_READ_MESSAGE_SUCCESS = 'MARK_ALL_READ_MESSAGE_SUCCESS';
export const MARK_ALL_READ_MESSAGE_FAILURE = 'MARK_ALL_READ_MESSAGE_FAILURE';
// Report V2

export const REPORT_V2_FORM_DATA = 'REPORT_V2_FORM_DATA';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const TOGGLE_PROGRAM_FAVORITE = 'TOGGLE_PROGRAM_FAVORITE';

export const REQUEST_TO_WITHDRAW_REQUEST = 'REQUEST_TO_WITHDRAW_REQUEST';
export const REQUEST_TO_WITHDRAW_SUCCESS = 'REQUEST_TO_WITHDRAW_SUCCESS';
export const REQUEST_TO_WITHDRAW_FAILURE = 'REQUEST_TO_WITHDRAW_FAILURE';

export const RESPOND_REQUEST_TO_WITHDRAW_REQUEST = 'RESPOND_REQUEST_TO_WITHDRAW_REQUEST';
export const RESPOND_REQUEST_TO_WITHDRAW_SUCCESS = 'RESPOND_REQUEST_TO_WITHDRAW_SUCCESS';
export const RESPOND_REQUEST_TO_WITHDRAW_FAILURE = 'RESPOND_REQUEST_TO_WITHDRAW_FAILURE';

//Submission Tag
export const FETCH_AVAILABLE_TAG_REQUEST = 'FETCH_AVAILABLE_TAG_REQUEST';
export const FETCH_AVAILABLE_TAG_SUCCESS = 'FETCH_AVAILABLE_TAG_SUCCESS';
export const FETCH_AVAILABLE_TAG_FAILURE = 'FETCH_AVAILABLE_TAG_FAILURE';
export const FETCH_AVAILABLE_TAG_CLEAR = 'FETCH_AVAILABLE_TAG_CLEAR';
export const FETCH_TAG_COLORS_REQUEST = 'FETCH_TAG_COLORS_REQUEST';
export const FETCH_TAG_COLORS_SUCCESS = 'FETCH_TAG_COLORS_SUCCESS';
export const FETCH_TAG_COLORS_FAILURE = 'FETCH_TAG_COLORS_FAILURE';
export const FETCH_TAG_COLORS_CLEAR = 'FETCH_TAG_COLORS_CLEAR';
export const ATTACH_TAG_REQUEST = 'ATTACH_TAG_REQUEST';
export const ATTACH_TAG_SUCCESS = 'ATTACH_TAG_SUCCESS';
export const ATTACH_TAG_FAILURE = 'ATTACH_TAG_FAILURE';
export const ATTACH_TAG_CLEAR = 'ATTACH_TAG_CLEAR';

export const CREATE_SUBMISSION_TAG_REQUEST = 'CREATE_SUBMISSION_TAG_REQUEST';
export const CREATE_SUBMISSION_TAG_SUCCESS = 'CREATE_SUBMISSION_TAG_SUCCESS';
export const CREATE_SUBMISSION_TAG_FAILURE = 'CREATE_SUBMISSION_TAG_FAILURE';
export const CREATE_SUBMISSION_TAG_CLEAR = 'CREATE_SUBMISSION_TAG_CLEAR';

export const UPDATE_SUBMISSION_TAG_REQUEST = 'UPDATE_SUBMISSION_TAG_REQUEST';
export const UPDATE_SUBMISSION_TAG_SUCCESS = 'UPDATE_SUBMISSION_TAG_SUCCESS';
export const UPDATE_SUBMISSION_TAG_FAILURE = 'UPDATE_SUBMISSION_TAG_FAILURE';
export const UPDATE_SUBMISSION_TAG_CLEAR = 'UPDATE_SUBMISSION_TAG_CLEAR';

export const FETCH_TERM_POLICY_REQUEST = 'FETCH_TERM_POLICY_REQUEST';
export const FETCH_TERM_POLICY_SUCCESS = 'FETCH_TERM_POLICY_SUCCESS';
export const FETCH_TERM_POLICY_FAILURE = 'FETCH_TERM_POLICY_FAILURE';

export const ACCEPT_TERM_REQUEST = 'ACCEPT_TERM_REQUEST';
export const ACCEPT_TERM_SUCCESS = 'ACCEPT_TERM_SUCCESS';
export const ACCEPT_TERM_FAILURE = 'ACCEPT_TERM_FAILURE';

export const DELETE_SUBMISSION_TAG_REQUEST = 'DELETE_SUBMISSION_TAG_REQUEST';
export const DELETE_SUBMISSION_TAG_SUCCESS = 'DELETE_SUBMISSION_TAG_SUCCESS';
export const DELETE_SUBMISSION_TAG_FAILURE = 'DELETE_SUBMISSION_TAG_FAILURE';

export const FETCH_AUTOMATION_QUALIFIER_REQUEST = 'FETCH_AUTOMATION_QUALIFIER_REQUEST';
export const FETCH_AUTOMATION_QUALIFIER_SUCCESS = 'FETCH_AUTOMATION_QUALIFIER_SUCCESS';
export const FETCH_AUTOMATION_QUALIFIER_FAILURE = 'FETCH_AUTOMATION_QUALIFIER_FAILURE';

export const FETCH_AUTOMATION_PRECONDITION_REQUEST = 'FETCH_AUTOMATION_PRECONDITION_REQUEST';
export const FETCH_AUTOMATION_PRECONDITION_SUCCESS = 'FETCH_AUTOMATION_PRECONDITION_SUCCESS';
export const FETCH_AUTOMATION_PRECONDITION_FAILURE = 'FETCH_AUTOMATION_PRECONDITION_FAILURE';

export const FETCH_AUTOMATION_CONDITION_REQUEST = 'FETCH_AUTOMATION_CONDITION_REQUEST';
export const FETCH_AUTOMATION_CONDITION_SUCCESS = 'FETCH_AUTOMATION_CONDITION_SUCCESS';
export const FETCH_AUTOMATION_CONDITION_FAILURE = 'FETCH_AUTOMATION_CONDITION_FAILURE';

export const CREATE_AUTOMATION_REQUEST = 'CREATE_AUTOMATION_REQUEST';
export const CREATE_AUTOMATION_SUCCESS = 'CREATE_AUTOMATION_SUCCESS';
export const CREATE_AUTOMATION_FAILURE = 'CREATE_AUTOMATION_FAILURE';

//Analytics dashboard(graph) and reports 
export const FETCH_GRAPH_INFORMATION = 'FETCH_GRAPH_INFORMATION';
export const FETCH_GRAPH_PROGRAM = 'FETCH_GRAPH_PROGRAM';
export const FETCH_ORGANIZATION_INSIGHTS = 'FETCH_ORGANIZATION_INSIGHTS';
export const FETCH_ORGANIZATION_INFORMATION = 'FETCH_ORGANIZATION_INFORMATION';

export const FETCH_POPULAR_PROGRAMS = 'FETCH_POPULAR_PROGRAMS';
export const FETCH_RECENT_CONNECTS = 'FETCH_RECENT_CONNECTS';
export const FETCH_ANALYTICS_REPORT = 'FETCH_ANALYTICS_REPORT';


export const GET_STANDARD_REPORTS_REQUEST = "GET_STANDARD_REPORTS_REQUEST";
export const GET_STANDARD_REPORTS_SUCCESS = "GET_STANDARD_REPORTS_SUCCESS";
export const GET_STANDARD_REPORTS_FAILURE = "GET_STANDARD_REPORTS_FAILURE"; 

export const GET_STANDARD_REPORT_NOTIFICATION_REQUEST = "GET_STANDARD_REPORT_NOTIFICATION_REQUEST";
export const GET_STANDARD_REPORT_NOTIFICATION_SUCCESS = "GET_STANDARD_REPORT_NOTIFICATION_SUCCESS";
export const GET_STANDARD_REPORT_NOTIFICATION_FAILURE = "GET_STANDARD_REPORT_NOTIFICATION_FAILURE";


export const DELETE_STANDARD_REPORT_REQUEST = "DELETE_STANDARD_REPORT_REQUEST";
export const DELETE_STANDARD_REPORT_SUCCESS = "DELETE_STANDARD_REPORT_SUCCESS";
export const DELETE_STANDARD_REPORT_FAILURE = "DELETE_STANDARD_REPORT_FAILURE"; 

export const MARK_READ_NOTIFICATION_REQUEST = "MARK_READ_NOTIFICATION_REQUEST";
export const MARK_READ_NOTIFICATION_SUCCESS = "MARK_READ_NOTIFICATION_SUCCESS";
export const MARK_READ_NOTIFICATION_FAILURE = "MARK_READ_NOTIFICATION_FAILURE"; 

export const MARK_ALL_READ_NOTIFICATION_REQUEST = "MARK_ALL_READ_NOTIFICATION_REQUEST";
export const MARK_ALL_READ_NOTIFICATION_SUCCESS = "MARK_ALL_READ_NOTIFICATION_SUCCESS";
export const MARK_ALL_READ_NOTIFICATION_FAILURE = "MARK_ALL_READ_NOTIFICATION_FAILURE"; 

export const GET_LIVE_REPORT_FLAG_REQUEST = "GET_LIVE_REPORT_FLAG_REQUEST"; 
export const GET_LIVE_REPORT_FLAG_SUCCESS = "GET_LIVE_REPORT_FLAG_SUCCESS"; 
export const GET_LIVE_REPORT_FLAG_FAILURE = "GET_LIVE_REPORT_FLAG_FAILURE"; 


export const UPDATE_LIVE_REPORT_FLAG_REQUEST = "UPDATE_LIVE_REPORT_FLAG_REQUEST"; 
export const UPDATE_LIVE_REPORT_FLAG_SUCCESS = "UPDATE_LIVE_REPORT_FLAG_SUCCESS"; 
export const UPDATE_LIVE_REPORT_FLAG_FAILURE = "UPDATE_LIVE_REPORT_FLAG_FAILURE"; 

export const TOGGLE_DATE_CHANGE_WARNING_MODAL = "TOGGLE_DATE_CHANGE_WARNING_MODAL"; 
export const UPDATED_DATE_ERROR_OBJECT = "UPDATED_DATE_ERROR_OBJECT"; 

export const GET_AUTOMATION_MAILER_REQUEST = "GET_AUTOMATION_MAILER_REQUEST";
export const GET_AUTOMATION_MAILER_SUCCESS = "GET_AUTOMATION_MAILER_SUCCESS";
export const GET_AUTOMATION_MAILER_FAILURE = "GET_AUTOMATION_MAILER_FAILURE"; 



export const ADD_APPLICATION_TAGS_REQUEST = "ADD_APPLICATION_TAGS_REQUEST";
export const ADD_APPLICATION_TAGS_SUCCESS = "ADD_APPLICATION_TAGS_SUCCESS";
export const ADD_APPLICATION_TAGS_CLEAR = "ADD_APPLICATION_TAGS_CLEAR"; 
export const ADD_APPLICATION_TAGS_FAILURE = "ADD_APPLICATION_TAGS_FAILURE"; 

export const REMOVE_APPLICATION_TAGS_REQUEST = "REMOVE_APPLICATION_TAGS_REQUEST";
export const REMOVE_APPLICATION_TAGS_SUCCESS = "REMOVE_APPLICATION_TAGS_SUCCESS";
export const REMOVE_APPLICATION_TAGS_CLEAR   = "REMOVE_APPLICATION_TAGS_CLEAR"; 
export const REMOVE_APPLICATION_TAGS_FAILURE = "REMOVE_APPLICATION_TAGS_FAILURE"; 

export const GET_PROGRAMS_REQUEST = "GET_PROGRAMS_REQUEST"; 
export const GET_PROGRAMS_SUCCESS = "GET_PROGRAMS_SUCCESS";
export const GET_PROGRAMS_FAILURE = "GET_PROGRAMS_FAILURE";  

export const GET_TOKENS_REQUEST = "GET_TOKENS_REQUEST";
export const GET_TOKENS_SUCCESS = "GET_TOKENS_SUCCESS";
export const GET_TOKENS_FAILURE = "GET_TOKENS_FAILURE"; 

export const FETCH_AUTOMATION_MAILER_HISTORY_REQUEST = "FETCH_AUTOMATION_MAILER_HISTORY_REQUEST";
export const FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS = "FETCH_AUTOMATION_MAILER_HISTORY_SUCCESS";
export const FETCH_AUTOMATION_MAILER_HISTORY_FAILURE = "FETCH_AUTOMATION_MAILER_HISTORY_FAILURE"; 

export const FETCH_AUTOMATION_FORM_HISTORY_REQUEST = "FETCH_AUTOMATION_FORM_HISTORY_REQUEST";
export const FETCH_AUTOMATION_FORM_HISTORY_SUCCESS = "FETCH_AUTOMATION_FORM_HISTORY_SUCCESS"; 
export const FETCH_AUTOMATION_FORM_HISTORY_FAILURE = "FETCH_AUTOMATION_FORM_HISTORY_FAILURE"; 


export const BATCH_ZIP_DOWNLOAD_REQUEST = "BATCH_ZIP_DOWNLOAD_REQUEST";
export const BATCH_ZIP_DOWNLOAD_SUCCESS = "BATCH_ZIP_DOWNLOAD_SUCCESS";
export const BATCH_ZIP_DOWNLOAD_FAILURE = "BATCH_ZIP_DOWNLOAD_FAILURE"; 


export const FETCH_WITHDRAW_DEFFERED_REASON = "FETCH_WITHDRAW_DEFFERED_REASON";

export const CREATE_PLAN_TERM_REQUEST = "CREATE_PLAN_TERM_REQUEST";
export const CREATE_PLAN_TERM_SUCCESS = "CREATE_PLAN_TERM_SUCCESS";
export const CREATE_PLAN_TERM_FAILURE = "CREATE_PLAN_TERM_FAILURE"; 

export const FETCH_EXCHANGE_BALANCE_REQUEST = "FETCH_EXCHANGE_BALANCE_REQUEST";
export const FETCH_EXCHANGE_BALANCE_SUCCESS = "FETCH_EXCHANGE_BALANCE_SUCCESS";
export const FETCH_EXCHANGE_BALANCE_CLEAR = "FETCH_EXCHANGE_BALANCE_CLEAR"; 
export const FETCH_EXCHANGE_BALANCE_FAILURE = "FETCH_EXCHANGE_BALANCE_FAILURE"; 

export const FETCH_BALANCE_VALUE_REQUEST = "FETCH_BALANCE_VALUE_REQUEST";
export const FETCH_BALANCE_VALUE_SUCCESS = "FETCH_BALANCE_VALUE_SUCCESS";
export const FETCH_BALANCE_VALUE_CLEAR = "FETCH_BALANCE_VALUE_CLEAR"; 
export const FETCH_BALANCE_VALUE_FAILURE = "FETCH_BALANCE_VALUE_FAILURE"; 

export const UPDATE_EXCHANGE_BALANCE_REQUEST = "UPDATE_EXCHANGE_BALANCE_REQUEST";
export const UPDATE_EXCHANGE_BALANCE_SUCCESS = "UPDATE_EXCHANGE_BALANCE_SUCCESS";
export const UPDATE_EXCHANGE_BALANCE_CLEAR = "UPDATE_EXCHANGE_BALANCE_CLEAR"; 
export const UPDATE_EXCHANGE_BALANCE_FAILURE = "UPDATE_EXCHANGE_BALANCE_FAILURE"; 

export const FETCH_AUTOMATION_TAG_HISTORY_REQUEST = "FETCH_AUTOMATION_TAG_HISTORY_REQUEST";
export const FETCH_AUTOMATION_TAG_HISTORY_SUCCESS = "FETCH_AUTOMATION_TAG_HISTORY_SUCCESS"; 
export const FETCH_AUTOMATION_TAG_HISTORY_FAILURE = "FETCH_AUTOMATION_TAG_HISTORY_FAILURE"; 


export const FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST = "FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_REQUEST";
export const FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS = "FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_SUCCESS"; 
export const FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE = "FETCH_AUTOMATION_CHANGE_STATUS_HISTORY_FAILURE"; 

export const FETCH_ORG_INTAKE_QUESTIONS_REQUEST = 'FETCH_ORG_INTAKE_QUESTIONS_REQUEST';
export const FETCH_ORG_INTAKE_QUESTIONS_SUCCESS = 'FETCH_ORG_INTAKE_QUESTIONS_SUCCESS';
export const FETCH_ORG_INTAKE_QUESTIONS_FAILURE = 'FETCH_ORG_INTAKE_QUESTIONS_FAILURE'; 

export const UPDATE_ORG_INTAKE_QUESTIONS_REQUEST = 'UPDATE_ORG_INTAKE_QUESTIONS_REQUEST';
export const UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS = 'UPDATE_ORG_INTAKE_QUESTIONS_SUCCESS';
export const UPDATE_ORG_INTAKE_QUESTIONS_FAILURE = 'UPDATE_ORG_INTAKE_QUESTIONS_FAILURE'; 

// Insight Dashboard
export const FETCH_TRAVELER_TRACKER = 'FETCH_TRAVELER_TRACKER'; 
export const FETCH_TERM_DEPARTING = 'FETCH_TERM_DEPARTING'; 
export const FETCH_UPCOMING_APPLICATION_DEADLINE = 'FETCH_UPCOMING_APPLICATION_DEADLINE'; 
export const FETCH_PROGRAM_DISTRIBUTION = 'FETCH_PROGRAM_DISTRIBUTION'; 
export const FETCH_APPLICATION_STARTED   = 'FETCH_APPLICATION_STARTED'; 
export const FETCH_FAVORITE_PROGRAM = 'FETCH_FAVORITE_PROGRAM'; 
export const FETCH_PARTICIPATION_BARRIERS = 'FETCH_PARTICIPATION_BARRIERS'; 
export const FETCH_TRAVELER_ACTIVITY = 'FETCH_TRAVELER_ACTIVITY';

export const GET_PROGRAM_BROCHURE_NEW = 'GET_PROGRAM_BROCHURE_NEW';

export const BATCH_MANUAL_DATA_SYNC_REQUEST = "BATCH_MANUAL_DATA_SYNC_REQUEST";
export const BATCH_MANUAL_DATA_SYNC_SUCCESS = "BATCH_MANUAL_DATA_SYNC_SUCCESS";
export const BATCH_MANUAL_DATA_SYNC_FAILURE = "BATCH_MANUAL_DATA_SYNC_FAILURE"; 


export const ADMIN_ADD_PLAN_FORM_TO_PLAN_REQUEST = 'ADMIN_ADD_PLAN_FORM_TO_PLAN_REQUEST';
export const ADMIN_ADD_PLAN_FORM_TO_PLAN_SUCCESS = 'ADMIN_ADD_PLAN_FORM_TO_PLAN_SUCCESS';
export const ADMIN_ADD_PLAN_FORM_TO_PLAN_FAILURE = 'ADMIN_ADD_PLAN_FORM_TO_PLAN_FAILURE'; 

export const ADMIN_UPDATE_PLAN_FORM_TO_PLAN_REQUEST = 'ADMIN_UPDATE_PLAN_FORM_TO_PLAN_REQUEST';
export const ADMIN_UPDATE_PLAN_FORM_TO_PLAN_SUCCESS = 'ADMIN_UPDATE_PLAN_FORM_TO_PLAN_SUCCESS';
export const ADMIN_UPDATE_PLAN_FORM_TO_PLAN_FAILURE = 'ADMIN_UPDATE_PLAN_FORM_TO_PLAN_FAILURE'; 

export const ADMIN_DELETE_PLAN_FORM_TO_PLAN_REQUEST = 'ADMIN_DELETE_PLAN_FORM_TO_PLAN_REQUEST';
export const ADMIN_DELETE_PLAN_FORM_TO_PLAN_SUCCESS = 'ADMIN_DELETE_PLAN_FORM_TO_PLAN_SUCCESS';
export const ADMIN_DELETE_PLAN_FORM_TO_PLAN_FAILURE = 'ADMIN_DELETE_PLAN_FORM_TO_PLAN_FAILURE'; 

export const PLAN_FORM_STATUS_UPDATE_REQUEST = 'PLAN_FORM_STATUS_UPDATE_REQUEST';
export const PLAN_FORM_STATUS_UPDATE_SUCCESS = 'PLAN_FORM_STATUS_UPDATE_SUCCESS';
export const PLAN_FORM_STATUS_UPDATE_FAILURE = 'PLAN_FORM_STATUS_UPDATE_FAILURE';
export const PLAN_FORM_STATUS_UPDATE_CLEAR = 'PLAN_FORM_STATUS_UPDATE_CLEAR';

export const UPDATE_LAST_VISITED_PRODUCT_REQUEST = "UPDATE_LAST_VISITED_PRODUCT_REQUEST";
export const UPDATE_LAST_VISITED_PRODUCT_SUCCESS = "UPDATE_LAST_VISITED_PRODUCT_SUCCESS";
export const UPDATE_LAST_VISITED_PRODUCT_FAILURE = "UPDATE_LAST_VISITED_PRODUCT_FAILURE"; 



export const ADD_PLAN_FORMS_TO_TRAVELERS_REQUEST = "ADD_PLAN_FORMS_TO_TRAVELERS_REQUEST";
export const ADD_PLAN_FORMS_TO_TRAVELERS_SUCCESS = "ADD_PLAN_FORMS_TO_TRAVELERS_SUCCESS";
export const ADD_PLAN_FORMS_TO_TRAVELERS_FAILURE = "ADD_PLAN_FORMS_TO_TRAVELERS_FAILURE"; 
