import {
  GET_PROGRAMS,
  GET_PROGRAMS_REQUEST,
  SET_PROGRAMS_SORT,
  TOGGLE_PROGRAMS_FILTER,
  SET_PROGRAMS_SEARCH_TERM,
  CLEAR_ALL_PROGRAMS_FILTERS,
} from '../actions/types';

const initialState = {
  programs: [],
  searchTerm: '',
  programsError: false,
  programsLoading: false,
  sortDirections: ['desc', 'asc'],
  sortProperties: ['internal_authorized', 'name'],
  selectedFilters: {
    cities: [],
    termName: [],
    countries: [],
    housingType: [],
    programName: [],
    programType: [],
    favorites: false,
    subjectAreas: [],
    organizationName: [],
    languageImmersion: 'no',
    languageOfInstruction: [],
    endDate: {
      date: null,
      range: null,
      dateInclusive: null,
    },
    startDate: {
      date: null,
      range: null,
      dateInclusive: null,
    },
  },
};

export default function(state = initialState, action) {
  switch (action.type) {

    case GET_PROGRAMS_REQUEST:
      return {
        ...state,
        programsLoading: true
      }

    case GET_PROGRAMS:
      return {
        ...state,
        programsLoading: false,
        programs: action.payload.data,
        programsError: action.payload.error,
      };
    case SET_PROGRAMS_SORT:
      return {
        ...state,
        sortDirections: action.payload.sortDirections,
        sortProperties: action.payload.sortProperties,
      };
    case SET_PROGRAMS_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case TOGGLE_PROGRAMS_FILTER:
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.name]: action.payload.value,
        },
      };
    case CLEAR_ALL_PROGRAMS_FILTERS:
      return {
        ...state,
        selectedFilters: {
          cities: [],
          termName: [],
          countries: [],
          housingType: [],
          programName: [],
          programType: [],
          favorites: false,
          subjectAreas: [],
          organizationName: [],
          languageImmersion: 'no',
          languageOfInstruction: [],
          endDate: {
            date: null,
            range: null,
            dateInclusive: null,
          },
          startDate: {
            date: null,
            range: null,
            dateInclusive: null,
          },
        },
      };
    default:
      return state;
  }
}
